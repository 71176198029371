import { LoginService } from '@admin/app/login/login.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthExpiredInterceptor implements HttpInterceptor {
  public constructor(
    private readonly _loginService: LoginService,
    private readonly _router: Router
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: unknown): Observable<never> => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this._loginService.logout();
            void this._router.navigate(['login']);
          }
        }

        return throwError((): unknown => err);
      })
    );
  }
}
