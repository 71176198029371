import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { QpAbstractInputComponent } from '@library/components/qp-input/qp-abstract-input.component';
import { EQpInputPasswordAutocompleteValue } from '@library/components/qp-input-password/qp-input-password.models';
import { QpAutocompleteDirective } from '@library/directives/qp-autocomplete/qp-autocomplete.directive';
import { QpAutofocusDirective } from '@library/directives/qp-autofocus/qp-autofocus.directive';
import { QpDisabledDirective } from '@library/directives/qp-disabled/qp-disabled.directive';
import { qpIsOptionalTrueStringBoolean } from '@library/functions/states/qp-is-optional-true-string-boolean';
import { QpStringBooleanType } from '@library/models/qp-boolean.models';
import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ViewChild,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  ElementRef,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { QimaOptionalType } from '@qima/ngx-qima';
import { has } from 'lodash/index';

type QpValueType = string;

/**
 * @description
 * @todo add a form group to improve the UI for the label and for the error messages
 * @todo add new features out of the box like label/input seamless link
 */
@Component({
  selector: 'qp-input-password',
  templateUrl: './qp-input-password.component.html',
  styleUrls: ['./qp-input-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    QpIconComponent,
    ReactiveFormsModule,
    QpDisabledDirective,
    QpAutofocusDirective,
    QpAutocompleteDirective,
    FormsModule,
    NgIf,
  ],
})
export class QpInputPasswordComponent extends QpAbstractInputComponent<QpValueType> implements OnChanges {
  /**
   * @description
   * Conversion table:
   * undefined      = true
   * null           = true
   * ''             = true
   * true           = true
   * false          = false
   * true (string)  = true
   * false (string) = false
   * FYI: overrides to improve the JSDoc
   * @example
   * <qp-input-password isDisabled></qp-input-password>           = disabled
   * <qp-input-password isDisabled=""></qp-input-password>        = disabled
   * <qp-input-password isDisabled="true"></qp-input-password>    = disabled
   * <qp-input-password [isDisabled]="true"></qp-input-password>  = disabled
   * <qp-input-password isDisabled="false"></qp-input-password>   = not disabled
   * <qp-input-password [isDisabled]="false"></qp-input-password> = not disabled
   * @default false
   */
  @Input()
  public isDisabled: QimaOptionalType<QpStringBooleanType> = false;

  /**
   * @description
   * Hide or show the password value
   * @default true
   */
  @Input()
  public isPasswordHidden = true;

  @Output()
  public readonly passwordVisibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * @type {EQpInputPasswordAutocompleteValue}
   * @default {@link EQpInputPasswordAutocompleteValue.CURRENT_PASSWORD}
   */
  @Input()
  public autocomplete: EQpInputPasswordAutocompleteValue = EQpInputPasswordAutocompleteValue.CURRENT_PASSWORD;

  @ViewChild('input')
  public input: QimaOptionalType<ElementRef<HTMLInputElement>> = undefined;

  public value: QpValueType = '';
  public readonly defaultValue: string = '';
  public isPasswordHiddenState = true;
  public type: 'password' | 'text' = 'password';
  protected readonly _componentSelector = 'qp-input-password';

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this._onIsPasswordHiddenChange(changes);
  }

  public onToggleSecretButtonClick(event: Readonly<MouseEvent | Event>): void {
    event.stopPropagation();
    this.isPasswordHiddenState = !this.isPasswordHiddenState;
    this.setType();
    this._changeDetectorRef.detectChanges();
    this.passwordVisibilityChange.emit(this.isPasswordHiddenState);
  }

  public setType(): void {
    this.type = this.isPasswordHiddenState ? 'password' : 'text';
  }

  private _onIsPasswordHiddenChange(changes: SimpleChanges): void {
    if (has(changes, 'isPasswordHidden') && changes.isPasswordHidden.currentValue !== changes.isPasswordHidden.previousValue) {
      this.isPasswordHiddenState = qpIsOptionalTrueStringBoolean(changes.isPasswordHidden.currentValue);
      this.setType();
      this._changeDetectorRef.detectChanges();
    }
  }
}
