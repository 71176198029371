import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { EQpLocale } from '@library/models/i18n/qp-locales.models';
import { QpLocaleLoaderService } from '@library/services/qp-locale/qp-locale-loader.service';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, BehaviorSubject } from 'rxjs';
import { tap, mapTo, flatMap } from 'rxjs/operators';

/**
 * @description
 * Exceptionally provided in root
 */
@Injectable({
  providedIn: 'root',
})
export class QpLocaleService extends AutoUnsubscriber {
  public readonly loadedLocales: Set<EQpLocale> = new Set<EQpLocale>();
  public readonly activeLocale$: BehaviorSubject<EQpLocale> = new BehaviorSubject<EQpLocale>(this._localeId);

  public constructor(
    private readonly _qpLoggerService: QpLoggerService,
    private readonly _translateService: TranslateService,
    @Inject(LOCALE_ID) private _localeId: EQpLocale,
    private readonly _qpLocaleLoaderService: QpLocaleLoaderService
  ) {
    super();
  }

  public init(): void {
    this._setCurrentLocaleToEnglish();
    this._logCurrentLocale();
  }

  public getCurrentLocale(): EQpLocale {
    return this._localeId;
  }

  public getCurrentLocale$(): Observable<EQpLocale> {
    return this.activeLocale$.asObservable();
  }

  public translate$(key: Readonly<string>, values: Readonly<Record<string, unknown>> = {}): Observable<string> {
    return this._translateService.get(key, values);
  }

  public instant(key: Readonly<string>, values: Readonly<Record<string, unknown>> = {}): string {
    return this._translateService.instant(key, values);
  }

  public loadLocale(localeId: Readonly<EQpLocale>): Promise<true | void> {
    return this._loadLocale(localeId);
  }

  public setCurrentLocale$(locale: Readonly<EQpLocale>): Observable<true> {
    return from(this.loadLocale(locale)).pipe(
      tap({
        next: (): void => {
          this._localeId = locale;
          this.activeLocale$.next(locale);
          this._qpLoggerService.info(`QpLocaleService => locale changed to ${this.getCurrentLocale()}`);
        },
      }),
      flatMap((): Observable<unknown> => {
        return this._translateService.use(locale);
      }),
      mapTo(true)
    );
  }

  private _loadLocale(localeId: Readonly<EQpLocale>): Promise<true | void> {
    if (this.loadedLocales.has(localeId)) {
      this._qpLoggerService.debug(`QpLocaleService => ${localeId} already loaded`);

      return Promise.resolve(true);
    }

    this._qpLoggerService.debug(`QpLocaleService => loading ${localeId}...`);

    return this._qpLocaleLoaderService
      .loadFile(localeId)
      .then((): true => {
        this.loadedLocales.add(localeId);
        this._qpLoggerService.debug(`QpLocaleService => ${localeId} loaded`);

        return true;
      })
      .catch((error: Error): void => {
        this._qpLoggerService.error(`QpLocaleService => could not load ${localeId}`);

        throw error;
      });
  }

  private _setCurrentLocaleToEnglish(): void {
    this.registerSubscription(this._translateService.use(EQpLocale.ENGLISH).subscribe());
  }

  private _logCurrentLocale(): void {
    this._qpLoggerService.group('QpLocaleService initialized', true).info(`Locale: ${this.getCurrentLocale()}`).groupEnd();
  }
}
