import { Observable } from 'rxjs';

/**
 * @description
 * This is voluntarily not a DI meaning that the Injectable decorator should not be added
 * Some components/decorators needs this service only when used
 * You should in that case provide the DI with a custom class provider in order to create the service with
 * your own implementation based on the project
 */
export abstract class QpPasswordService {
  public abstract getSecurityScore$(password: Readonly<string>): Observable<unknown>;

  public abstract getPasswordRules$(): Observable<unknown>;
}
