<div>
  <div class="qp-row qp-justify-content-center">
    <div class="qp-col-md-4">
      <h1>{{ 'reset.finish.title.label' | translate }}</h1>

      <div *ngIf="keyMissing" class="qp-alert qp-alert-danger">
        <strong>{{ 'reset.finish.messages.keymissing' | translate }}</strong>
      </div>

      <div *ngIf="!success && !keyMissing" class="qp-alert qp-alert-warning">
        <p>{{ 'reset.finish.messages.info' | translate }}</p>
      </div>

      <div *ngIf="error" class="qp-alert qp-alert-danger">
        <p>
          {{ 'reset.finish.messages.error' | translate }}
        </p>
      </div>

      <p *ngIf="success" class="qp-alert qp-alert-success">
        <span
          ><strong>{{ 'reset.finish.messages.success' | translate }}</strong> {{ 'reset.finish.messages.please' | translate }}
        </span>
        <a (click)="login()" class="qp-alert-link">{{ 'global.messages.info.authenticated.link' | translate }} </a>
        .
      </p>

      <div *ngIf="doNotMatch" class="qp-alert qp-alert-danger">
        {{ 'global.messages.error.dontmatch' | translate }}
      </div>

      <div *ngIf="!keyMissing">
        <form #passwordForm="ngForm" (ngSubmit)="finishReset()" *ngIf="!success" name="form" role="form">
          <div class="qp-form-group">
            <label for="password">{{ 'global.form.newpassword.label' | translate }} </label>
            <input
              #passwordInput="ngModel"
              [(ngModel)]="resetAccount.password"
              class="qp-form-control"
              id="password"
              maxlength="50"
              minlength="4"
              name="password"
              placeholder="{{ 'global.form.newpassword.placeholder' | translate }}"
              required
              type="password"
            />
            <div *ngIf="passwordInput.dirty && passwordInput.invalid">
              <small *ngIf="passwordInput.errors.required" class="qp-form-text qp-text-danger">
                {{ 'global.messages.validate.newpassword.required' | translate }}
              </small>
              <small *ngIf="passwordInput.errors.minlength" class="qp-form-text qp-text-danger">
                {{ 'global.messages.validate.newpassword.minlength' | translate }}
              </small>
              <small *ngIf="passwordInput.errors.maxlength" class="qp-form-text qp-text-danger">
                {{ 'global.messages.validate.newpassword.maxlength' | translate }}
              </small>
            </div>
            <!-- @todo fix it and use the same behaviour than the one that was previously coded in one -->
            <qp-password-strength-bar></qp-password-strength-bar>
          </div>

          <div class="qp-form-group">
            <label for="confirmPassword">{{ 'global.form.confirmpassword.label' | translate }} </label>
            <input
              #confirmPasswordInput="ngModel"
              [(ngModel)]="confirmPassword"
              class="qp-form-control"
              id="confirmPassword"
              maxlength="50"
              minlength="4"
              name="confirmPassword"
              placeholder="{{ 'global.form.confirmpassword.placeholder' | translate }}"
              required
              type="password"
            />
            <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
              <small *ngIf="confirmPasswordInput.errors.required" class="qp-form-text qp-text-danger">
                {{ 'global.messages.validate.confirmpassword.required' | translate }}
              </small>
              <small *ngIf="confirmPasswordInput.errors.minlength" class="qp-form-text qp-text-danger">
                {{ 'global.messages.validate.confirmpassword.minlength' | translate }}
              </small>
              <small *ngIf="confirmPasswordInput.errors.maxlength" class="qp-form-text qp-text-danger">
                {{ 'global.messages.validate.confirmpassword.maxlength' | translate }}
              </small>
            </div>
          </div>
          <button [disabled]="!passwordForm.form.valid" class="qp-btn qp-btn-primary" type="submit">
            {{ 'reset.finish.form.button' | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
