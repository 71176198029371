import { qpNgxQimaTranslationFactory } from '@library/factories/qp-ngx-qima-translation.factory';
import { QpLocaleService } from '@library/services/qp-locale/qp-locale.service';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { FactoryProvider } from '@angular/core';
import { QIMA_TRANSLATION_FACTORY_TOKEN } from '@qima/ngx-qima';

/**
 * @description
 * Replace the factory of ngx-qima with our own
 * In order to provide the translations for components that need those
 */
export const QP_NGX_QIMA_TRANSLATION_PROVIDER: FactoryProvider = {
  deps: [QpLocaleService, QpLoggerService],
  provide: QIMA_TRANSLATION_FACTORY_TOKEN,
  useFactory: qpNgxQimaTranslationFactory,
};
