// @ts-strict-ignore
import { AccountService } from '@admin/app/core/auth/account.service';
import { LanguageHelperService } from '@admin/app/core/language/language.helper';
import { IAccount } from '@admin/app/core/user/account.model';
import { QpLabelComponent } from '@library/components/qp-label/qp-label.component';
import { QpTextComponent } from '@library/components/qp-text/qp-text.component';
import { QpButtonDirective, EQpButtonDirectiveSize, EQpButtonDirectiveType } from '@library/directives/qp-button/qp-button.directive';
import { QpFindLanguageFromKeyPipe } from '@library/pipes/qp-find-language-from-key/qp-find-language-from-key.pipe';
import { NgIf, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'admin-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    QpLabelComponent,
    QpTextComponent,
    TranslateModule,
    NgFor,
    QpButtonDirective,
    QpFindLanguageFromKeyPipe,
  ],
})
export class SettingsComponent implements OnInit {
  public error: string;
  public success: string;
  public settingsAccount: Partial<IAccount>;
  public languages: string[];
  public buttonSizes: typeof EQpButtonDirectiveSize = EQpButtonDirectiveSize;
  public buttonTypes: typeof EQpButtonDirectiveType = EQpButtonDirectiveType;

  public constructor(
    private readonly _accountService: AccountService,
    private readonly _translateService: TranslateService,
    private readonly _languageHelperService: LanguageHelperService
  ) {}

  public ngOnInit(): void {
    this._accountService.identity().then((account): void => {
      this.settingsAccount = this.copyAccount(account);
    });
    this._languageHelperService.getAll().then((languages): void => {
      this.languages = languages;
    });
  }

  public save(): void {
    this._accountService.save$(this.settingsAccount).subscribe({
      next: (): void => {
        this.error = null;
        this.success = 'OK';
        this._accountService.identity(true).then((account): void => {
          this.settingsAccount = this.copyAccount(account);
        });

        if (this.settingsAccount.langKey !== this._translateService.currentLang) {
          this._translateService.use(this.settingsAccount.langKey);
        }
      },
      error: (): void => {
        this.success = null;
        this.error = 'ERROR';
      },
    });
  }

  public copyAccount(account): IAccount {
    return {
      activated: account.activated,
      email: account.email,
      firstName: account.firstName,
      langKey: account.langKey,
      lastName: account.lastName,
      login: account.login,
      imageUrl: account.imageUrl,
    };
  }
}
