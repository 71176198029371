import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentRef, InjectionToken } from '@angular/core';

export const MODAL_OVERLAY = new InjectionToken<OverlayRef>('CONTAINER_DATA');

export class ModalRef<C> {
  public constructor(
    public readonly componentRef: ComponentRef<C>,
    private readonly _overlayRef: OverlayRef
  ) {}

  public close(): void {
    this._overlayRef.dispose();
  }
}
