import { SERVER_API_URL } from '@admin/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PasswordResetInitService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public save(mail: string): Observable<void> {
    return this._httpClient.post<void>(`${SERVER_API_URL}api/account/reset-password/init`, mail);
  }
}
