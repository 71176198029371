import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaSnackbarModule, EQimaPosition } from '@qima/ngx-qima';

@NgModule({
  imports: [
    TranslateModule,
    OverlayModule,
    QimaSnackbarModule.forRoot({
      position: EQimaPosition.BOTTOM,
      duration: 3500,
    }),
  ],
  providers: [QpNotificationBarService],
})
export class QpNotificationBarModule {}
