import { IDisplayedRoute } from '@admin/app/shared/service/route/displayed-route';
import { DisplayedRoutesService, EMenuPosition } from '@admin/app/shared/service/route/displayed-routes.service';
import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { QpButtonDirective, EQpButtonDirectiveSize, EQpButtonDirectiveType } from '@library/directives/qp-button/qp-button.directive';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'qp-qima-subnav',
  templateUrl: './qima-subnav.component.html',
  styleUrls: ['./qima-subnav.component.scss'],
  standalone: true,
  imports: [NgClass, NgFor, RouterLinkActive, NgIf, QpButtonDirective, RouterLink, QpIconComponent, TranslateModule],
})
export class QimaSubnavComponent implements OnInit {
  public currentMenuPosition = EMenuPosition.COLLAPSE;
  public menuPositions = EMenuPosition;
  public subMenuList: IDisplayedRoute[] = [];
  public readonly iconNames: typeof EQpIconName = EQpIconName;
  public buttonSizes: typeof EQpButtonDirectiveSize = EQpButtonDirectiveSize;
  public buttonTypes: typeof EQpButtonDirectiveType = EQpButtonDirectiveType;

  public constructor(private readonly _displayedRoutesService: DisplayedRoutesService) {}

  public ngOnInit(): void {
    this._displayedRoutesService.menuPosition.subscribe((menuPosition: EMenuPosition): void => {
      this.currentMenuPosition = menuPosition;
    });
    this._displayedRoutesService.getRoutes().subscribe((subMenuList): void => {
      this.subMenuList = subMenuList;
    });
  }
}
