import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { EQpLocale } from '@library/models/i18n/qp-locales.models';
import { QpLocaleService } from '@library/services/qp-locale/qp-locale.service';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { QimaTranslationFactory, EQimaTranslationKey } from '@qima/ngx-qima';
import { Observable, BehaviorSubject } from 'rxjs';

export class QpNgxQimaTranslationService extends AutoUnsubscriber implements QimaTranslationFactory {
  public readonly activeLanguageSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(this._qpLocaleService.getCurrentLocale());

  public constructor(
    private readonly _qpLocaleService: QpLocaleService,
    private readonly _qpLoggerService: QpLoggerService
  ) {
    super();

    this._watchCurrentLocaleChange();
  }

  public translate$(translationKey: Readonly<EQimaTranslationKey>, extra?: Readonly<Record<string, unknown>>): Observable<string> {
    return this._qpLocaleService.translate$(`ngx-qima.${translationKey}`, extra);
  }

  private _watchCurrentLocaleChange(): void {
    this.registerSubscription(
      this._qpLocaleService.getCurrentLocale$().subscribe({
        next: (locale: Readonly<EQpLocale>): void => {
          this._qpLoggerService.info(`Changing the active language of ngx-qima to ${locale}`);
          this.activeLanguageSubject$.next(locale);
        },
      })
    );
  }
}
