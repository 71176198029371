import { QpHttpEncoder } from '@library/classes/qp-htpp-encoder/qp-http-encoder';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class QpHttpParamsInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const params = new HttpParams({ encoder: new QpHttpEncoder(), fromString: req.params.toString() });

    return next.handle(req.clone({ params }));
  }
}
