<div [ngClass]="currentMenuPosition === menuPositions.COLLAPSE ? 'collapse' : 'expand'" class="nav-sub-menu">
  <div *ngFor="let entity of subMenuList" class="entry-menu" routerLinkActive="enable">
    <div class="menu-title qp-d-flex qp-align-items-center">
      <span class="item--highlighted">{{ entity.title | translate }}</span>
      <div *ngIf="entity.defaultActionRoute" class="margin-left-auto">
        <button
          [attr.data-cy]="entity.defaultActionRoute.dataCy"
          [qpButtonSize]="buttonSizes.MEDIUM"
          [qpButtonType]="buttonTypes.HERO_BLUE"
          [routerLink]="entity.defaultActionRoute.routerLink"
          qpButton
          routerLinkActive="enable"
        >
          {{ entity.defaultActionRoute.title | translate }}
          <qp-icon [name]="iconNames.IC_ADD_MEDIUM" class="icon"></qp-icon>
        </button>
      </div>
    </div>
    <div class="entry-sub-menu">
      <div *ngFor="let subEntity of entity.children">
        <a *ngIf="!subEntity.isDefaultAction">
          <span [routerLink]="subEntity.routerLink" class="submenu-title justify-content-around interactive-1" routerLinkActive="enable">{{
            subEntity.title | translate
          }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
