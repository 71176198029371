import { ChangelogComponent } from '@admin/app/account/changelog/changelog.component';
import { DocsComponent } from '@admin/app/account/docs/docs.component';
import { PasswordComponent } from '@admin/app/account/password/password.component';
import { SettingsComponent } from '@admin/app/account/settings/settings.component';
import { AdminEntitiesModule } from '@admin/app/shared/shared-entities.module';
import { AdminSharedModule } from '@admin/app/shared/shared.module';
import { QpLabelComponent } from '@library/components/qp-label/qp-label.component';
import { QpPasswordFieldComponent } from '@library/components/qp-password-field/qp-password-field.component';
import { QpTextComponent } from '@library/components/qp-text/qp-text.component';
import { QpButtonDirective } from '@library/directives/qp-button/qp-button.directive';
import { QpFindLanguageFromKeyPipe } from '@library/pipes/qp-find-language-from-key/qp-find-language-from-key.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    AdminSharedModule,
    AdminEntitiesModule,
    QpLabelComponent,
    QpButtonDirective,
    QpFindLanguageFromKeyPipe,
    QpTextComponent,
    TranslateModule,
    FormsModule,
    QpPasswordFieldComponent,
    CommonModule,
    PasswordComponent,
    ChangelogComponent,
    DocsComponent,
    SettingsComponent,
  ],
  exports: [PasswordComponent, ChangelogComponent, DocsComponent],
})
export class AccountModule {}
