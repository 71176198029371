// @ts-strict-ignore
import { AccountService } from '@admin/app/core/auth/account.service';
import { Account } from '@admin/app/core/user/account.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qp-changelog',
  templateUrl: './changelog.component.html',
  standalone: true,
})
export class ChangelogComponent implements OnInit {
  public account: Account;

  public constructor(private readonly _accountService: AccountService) {}

  public ngOnInit(): void {
    this._accountService.identity().then((account): void => {
      this.account = account;
    });
  }
}
