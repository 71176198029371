import { AccountService } from '@admin/app/core/auth/account.service';
import { Account } from '@admin/app/core/user/account.model';
import { LoginService } from '@admin/app/login/login.service';
import { IDisplayedRoute } from '@admin/app/shared/service/route/displayed-route';
import { DisplayedRoutesService, EMenuPosition } from '@admin/app/shared/service/route/displayed-routes.service';
import { AuthorityLabelPipe } from '@admin/app/shared/util/authority.pipe';
import { QpSafeUrlType } from '@library/models/qp-url.models';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';

@Component({
  selector: 'qp-qima-desktop-main-navbar',
  templateUrl: './qima-desktop-main-navbar.component.html',
  styleUrls: ['./qima-desktop-main-navbar.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NzIconModule, NzMenuModule, NgFor, RouterLinkActive, RouterLink, AuthorityLabelPipe, TranslateModule],
})
export class QimaDesktopMainNavbarComponent implements OnInit {
  public isCollapsed = true;
  public name: string = '';
  public account: QimaOptionalType<Account>;
  public picture: QpSafeUrlType | undefined;
  public brandName: string = '';
  public menuPositions: EMenuPosition = EMenuPosition.COLLAPSE;
  public menuList: IDisplayedRoute[] = [];

  public constructor(
    private readonly _displayedRoutesService: DisplayedRoutesService,
    private readonly _accountService: AccountService,
    private readonly _loginService: LoginService,
    private readonly _router: Router
  ) {}

  public ngOnInit(): void {
    this._displayedRoutesService.getRoutes().subscribe((entities: IDisplayedRoute[]): void => {
      this.menuList = entities;
    });
    this._accountService.identity().then((account): void => {
      this.account = account;
      this._displayedRoutesService.menuPosition.next(this.isCollapsed ? EMenuPosition.COLLAPSE : EMenuPosition.EXPAND);
    });
  }

  public toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
    this.menuPositions = this.isCollapsed ? EMenuPosition.COLLAPSE : EMenuPosition.EXPAND;
    this._displayedRoutesService.menuPosition.next(this.menuPositions);
  }

  public logout(): void {
    this._loginService.logout();
    void this._router.navigate(['login']);
  }
}
