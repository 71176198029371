<div
  [id]="rootId"
  class="qp-input-password"
  [class.qp-input-password--has-focus]="hasFocus"
  [class.qp-input-password--is-disabled]="isDisabledState"
  [class.qp-input-password--has-clear-button]="!isDisabledState && value.length > 0 && (hasMouseHover || hasFocus)"
  [class.qp-input-password--has-ui-validation]="hasValidatorsState"
  [class.qp-input-password--was-focused]="wasFocusedOnce"
  [class.qp-input-password--was-blurred]="wasBlurredOnce"
  (click)="onClick()"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [ngClass]="['qp-input-password--size-' + size, 'qp-input-password--border-' + border]"
  tabindex="-1"
>
  <qp-icon class="qp-input-password__icon-left" [name]="iconNames.IC_PASSWORD"></qp-icon>
  <input
    #input
    class="qp-input-password__input"
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut()"
    [attr.placeholder]="placeholder"
    [attr.type]="type"
    [attr.name]="name"
    [attr.id]="id"
    [qpDisabled]="isDisabledState"
    [qpAutofocus]="isAutofocusedState"
    [qpAutocomplete]="autocomplete"
    [ngModel]="value"
    size="100"
    (ngModelChange)="onValueChange($event)"
  />
  <div
    class="qp-input-password__clear-button"
    *ngIf="!isDisabledState && value.length > 0 && (hasMouseHover || hasFocus)"
    (click)="onClearButtonClick($event)"
    (keydown.enter)="onClearButtonClick($event)"
  >
    <qp-icon class="qp-input-password__clear-button-icon" [name]="iconNames.CLEAR"></qp-icon>
  </div>
  <div
    class="qp-input-password__toggle-secret-button"
    *ngIf="!isDisabledState"
    (click)="onToggleSecretButtonClick($event)"
    (keydown.enter)="onToggleSecretButtonClick($event)"
  >
    <qp-icon
      class="qp-input-password__toggle-secret-button-icon"
      [name]="isPasswordHiddenState ? iconNames.IC_PASSWORD_SHOW : iconNames.IC_PASSWORD_HIDE"
    ></qp-icon>
  </div>
</div>
