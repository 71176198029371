<div class="login-page">
  <div class="page-container">
    <form [formGroup]="loginForm" class="login-container" data-cy="login-container">
      <img [src]="'assets/pictures/qima-one-logo.png'" alt="This is the qima logo in the login page." class="qima-logo" />
      <qp-label *ngIf="httpStatusCodes.LOCKED === loginErrorStatus" data-cy="error-login" error="true" type="informative">
        <div [innerHTML]="'login.messages.error.locked' | translate"></div>
      </qp-label>
      <qp-label
        *ngIf="loginErrorStatus && httpStatusCodes.LOCKED !== loginErrorStatus"
        data-cy="error-login"
        error="true"
        type="informative"
      >
        <div [innerHTML]="'login.messages.error.authentication' | translate"></div>
      </qp-label>
      <div class="input-login">
        <div class="login-row">
          <qp-label for="email">
            {{ 'login.form.email.label' | translate }}
          </qp-label>
          <qp-text
            [ngClass]="{ 'ng-invalid': hasAuthenticationError }"
            [placeholder]="'login.form.email.placeholder' | translate"
            data-cy="email"
            type="email"
            formControlName="email"
            name="email"
            [qpTextAutocomplete]="autocompleteValues.EMAIL"
          ></qp-text>
        </div>

        <div class="login-row">
          <qp-label>
            {{ 'login.form.password.label' | translate }}
          </qp-label>
          <qp-text
            [ngClass]="{ 'ng-invalid': hasAuthenticationError }"
            [placeholder]="'login.form.password.placeholder' | translate"
            [qpTextAutocomplete]="autocompleteValues.PASSWORD"
            data-cy="password"
            formControlName="password"
            type="password"
          ></qp-text>
        </div>

        <div class="login-row">
          <qp-checkbox formControlName="isRemembered">
            <qp-label class="caption" type="informative">{{ 'login.form.rememberme' | translate }}</qp-label>
          </qp-checkbox>
          <div (click)="requestResetPassword()" class="password-forgotten">
            <span class="forgot-password">{{ 'login.password.forgot' | translate }}</span>
          </div>
        </div>
      </div>
      <button
        (click)="login()"
        [disabled]="!loginForm.valid"
        [qpButtonSize]="buttonSizes.LARGE"
        [qpButtonType]="buttonTypes.HERO_RED"
        data-cy="sign-in"
        qpButton
      >
        {{ 'login.form.button' | translate }}
      </button>
    </form>
  </div>
  <div *ngIf="isIcpNumberHasToBeDisplayed" id="icp">
    <a href="http://www.beian.miit.gov.cn/" rel="noopener noreferrer" target="_blank">粤ICP备18048722号 </a>
  </div>
</div>
