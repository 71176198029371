<div>
  <div class="qp-row qp-justify-content-center">
    <div class="qp-col-md-8">
      <h2>
        <em>2019-09-11</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Import checklists from files</li>
        <li>Accept or reject an inspection report</li>
        <li>Consult the default inspection workflow</li>
        <li>Consult a list of the purchase orders</li>
        <li>Modify an existing checklist</li>
        <li>Display the sample of each question during the inspection</li>
        <li>Quick sign the factory disclaimer during the inspection</li>
        <li>Hide main menu during an inspection</li>
        <li>Add general remarks during an offline inspection</li>
        <li>Add a new user profile : Supervisor</li>
      </ul>
      <hr />
      <h2>
        <em>2019-08-28</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>See a report of a completed inspection</li>
        <li>See a product page with all the informations</li>
        <li>Add instructions, expected results and sampling size for a question in a checklist</li>
        <li>Display instructions and expected results during an inspection</li>
        <li>Previsualize data before inspector import with error management</li>
        <li>Draw on picture during an inspection</li>
        <li>Calculate the number of cartons to pick during an inspection</li>
        <li>Force the offline mode during an inspection</li>
        <li>Integrate and display the defect list during inspection</li>
      </ul>
      <hr />
      <h2>
        <em>2019-08-14</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Add pictures to a test of a checklist during an inspection</li>
        <li>Validate a checklist in offline mode during an inspection</li>
        <li>Ask for confirmation before quit a creation workflow</li>
      </ul>
      <hr />
      <h2>
        <em>2019-07-31</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Schedule an inspection step by step</li>
        <li>Create a product with product identifiers</li>
        <li>Consult my products</li>
        <li>Show the protocol to the inspector</li>
        <li>Add general remarks to an inspection</li>
        <li>Complete and save an inspection from a workflow</li>
        <li>Mobile navigation : menu, homepage, login</li>
      </ul>
      <hr />
      <h2>
        <em>2019-07-17</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Scan barcodes when inspector realizes an inspection</li>
        <li>Add account informations in the navigation bar</li>
        <li>Show the protocol of test during an inspection</li>
        <li>Create an home page for brand user</li>
      </ul>
      <hr />
      <h2>
        <em>2019-07-02</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Restrict password request attempts before a temporary blocker</li>
        <li>Save the user's session </li>
        <li>Import a inspector file</li>
        <li>Add a profile picture for inspector user</li>
        <li>Map data during an inspector import </li>
        <li>Previsualize data during an inspector import</li>
        <li>Link a protocol to an inspection</li>
        <li>Freeze inspection data</li>
        <li>Add ICS file for inspection notifications</li>
        <li>Save photos in offline mode</li>
        <li>Notify the brand the completed inspection</li>
      </ul>
      <hr />
      <h2>
        <em>2019-06-19</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Add geographic coordinates to a factory address</li>
        <li>Create a purchase order with product informations</li>
        <li>Import a inspector file</li>
        <li>Open the disclaimer during an inspection</li>
        <li>Take photos during an inspection</li>
        <li>Complete an inspection with timestamp and timezone</li>
        <li>Create a protocol and see a list of protocols</li>
      </ul>
      <hr />
      <h2>
        <em>2019-06-05</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Start an inspection (also in offline mode) for inspectors</li>
        <li>Add an administrative area field in address block</li>
        <li>Save automatically geographical coordinates when an inspector starts an inspection</li>
        <li>Integrate the lateral navigation bar</li>
      </ul>
      <hr />
      <h2>
        <em>2019-05-22</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Add company registration number to identify vendors and factories</li>
        <li>Modify a rejected inspection by an inspector</li>
        <li>Notify the factory about a validated inspection</li>
        <li>Connect to the platform in offline mode for an inspector user and consult the list of inspections</li>
      </ul>
      <hr />
      <h2>
        <em>2019-05-07</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Add the GTIN field to a product with the validity controls</li>
        <li>Replace the term "Supplier" by "Vendor" in the different pages of the application</li>
        <li>Notify the brand when an inspection is rejected by an inspector</li>
      </ul>
      <hr />
      <h2>
        <em>2019-04-17</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Add personalized specifications to a product</li>
        <li>Consult suppliers linked to the brand</li>
        <li>Send an mail notification to the inspector about the inspection</li>
        <li>Modify an existing supplier</li>
        <li>Create product variant axis and values</li>
        <li>Accept an inspection</li>
      </ul>
      <hr />
      <h2>
        <em>2019-04-02</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Declare a vendor as a factory</li>
        <li>A brand user can create an inspection with all the required informations</li>
      </ul>
      <h3>Fixes</h3>
      <ul>
        <li>Change default passwords for brand users</li>
      </ul>
      <hr />
      <h2>
        <em>2019-03-20</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Create a factory within a vendor</li>
        <li>Invite a new inspector into the application</li>
        <li>Notify a new inspector by an email notification</li>
        <li>Confirm the inspector account</li>
        <li>Connect as an inspector</li>
      </ul>
      <h3>Fixes</h3>
      <ul>
        <li>Secure the procedure "Forgot your password"</li>
      </ul>
      <hr />
      <h2>
        <em>2019-03-05</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>"Forgot your password" procedure</li>
        <li>Changelog page</li>
      </ul>
      <h3>Fixes</h3>
      <ul>
        <li>Delete the "main product line" in the "Create a vendor" page</li>
        <li>Hide the possibility to create user account</li>
      </ul>
      <hr />
      <h2>
        <em>2019-02-20</em>
      </h2>
      <h3>Features</h3>
      <ul>
        <li>Connect to the platform with a login/password</li>
        <li>Create a simple product</li>
        <li>Create a simple vendor</li>
      </ul>
      <hr />
    </div>
  </div>
</div>
