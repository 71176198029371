// @ts-strict-ignore
import { QpInputPasswordComponent } from '../qp-input-password/qp-input-password.component';
import { QpLabelComponent } from '../qp-label/qp-label.component';
import { QpPasswordStrengthBarComponent } from '../qp-password-strength-bar/qp-password-strength-bar.component';
import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { QpPasswordService } from '@library/classes/qp-password/qp-password.service';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { EQpInputPasswordAutocompleteValue } from '@library/components/qp-input-password/qp-input-password.models';
import { IQpPasswordFieldRules, IQpPasswordForm, IQpPasswordTitle } from '@library/components/qp-password-field/qp-password-field.models';
import { qpEqualFieldsValidator } from '@library/validators/qp-equal-fields-validator';
import { qpPasswordSecurityScoreValidator } from '@library/validators/qp-password-security-score-validator';
import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Validators, ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl, ControlsOf } from '@ngneat/reactive-forms';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'qp-password-field',
  templateUrl: './qp-password-field.component.html',
  styleUrls: ['./qp-password-field.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    QpLabelComponent,
    QpInputPasswordComponent,
    QpPasswordStrengthBarComponent,
    NgIf,
    TranslateModule,
  ],
})
export class QpPasswordFieldComponent extends AutoUnsubscriber implements OnInit {
  public passwordMinLength = 0;
  public passwordMaxLength = +Infinity;
  public passwordFormGroup: FormGroup<ControlsOf<IQpPasswordForm>> = new FormGroup<ControlsOf<IQpPasswordForm>>(
    {
      password: new FormControl('', [], [qpPasswordSecurityScoreValidator(this._qpPasswordService)]),
      confirmPassword: new FormControl(''),
    },
    [qpEqualFieldsValidator('password', 'confirmPassword')]
  );

  public readonly iconNames: typeof EQpIconName = EQpIconName;
  public readonly inputPasswordAutocompletes: typeof EQpInputPasswordAutocompleteValue = EQpInputPasswordAutocompleteValue;

  @Input()
  public isFullWidth = false;

  @Input()
  public direction: 'row' | 'column' = 'row';

  @Input()
  public passwordTitle: QimaOptionalType<IQpPasswordTitle>;

  @Input()
  public confirmPasswordTitle: QimaOptionalType<IQpPasswordTitle>;

  /**
   * @deprecated
   * Not Angular way
   * The child should not be responsible of the form creation
   * @type {EventEmitter<FormGroup<IQpPasswordForm>>}
   */
  @Output()
  public linkToParent: EventEmitter<FormGroup<ControlsOf<IQpPasswordForm>>> = new EventEmitter<FormGroup<ControlsOf<IQpPasswordForm>>>();

  public get passwordLabelPlaceholder(): IQpPasswordTitle {
    return this.passwordTitle
      ? { label: this.passwordTitle.label, placeholder: this.passwordTitle.placeholder }
      : { label: 'global.form.newpassword.label', placeholder: 'global.form.newpassword.placeholder' };
  }

  public get confirmPasswordLabelPlaceholder(): IQpPasswordTitle {
    return this.confirmPasswordTitle
      ? { label: this.confirmPasswordTitle.label, placeholder: this.confirmPasswordTitle.placeholder }
      : { label: 'global.form.confirmpassword.label', placeholder: 'global.form.confirmpassword.placeholder' };
  }

  public get passwordField(): FormControl<string> {
    return this.passwordFormGroup.controls.password;
  }

  public get confirmPasswordField(): FormControl<string> {
    return this.passwordFormGroup.controls.confirmPassword;
  }

  public get password(): string {
    return this.passwordField.value;
  }

  public set password(pass: string) {
    this.passwordField.setValue(pass);
  }

  public get confirmPassword(): string {
    return this.confirmPasswordField.value;
  }

  public set confirmPassword(pass: string) {
    this.confirmPasswordField.setValue(pass);
  }

  public constructor(private readonly _qpPasswordService: QpPasswordService) {
    super();
  }

  public ngOnInit(): void {
    this.linkToParent.emit(this.passwordFormGroup);
    this.registerSubscription(
      this._qpPasswordService.getPasswordRules$().subscribe((rules: IQpPasswordFieldRules): void => {
        this.passwordMinLength = rules.minLength;
        this.passwordMaxLength = rules.maxLength;
        this.passwordFormGroup.controls.password.setValidators([
          Validators.minLength(this.passwordMinLength),
          Validators.maxLength(this.passwordMaxLength),
        ]);
        this.passwordFormGroup.controls.confirmPassword.setValidators([
          Validators.required,
          Validators.minLength(this.passwordMinLength),
          Validators.maxLength(this.passwordMaxLength),
        ]);
      })
    );

    this.registerSubscription(
      this.passwordFormGroup.controls.password.statusChanges.subscribe((e: Readonly<string>): void => {
        if (e === 'INVALID' && this.passwordFormGroup.controls.password.errors.minlength) {
          this.passwordFormGroup.controls.password['securityScore'] = -1;
        }
      })
    );
  }
}
