import { QpLocaleService } from '@library/services/qp-locale/qp-locale.service';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { QpNgxQimaTranslationService } from '@library/services/qp-ngx-qima-translation/qp-ngx-qima-translation-service';

/**
 * @description
 * Factory used to create the translation service for ngx-qima
 * @param {QpLocaleService} qpLocaleService The internal service used to translate QIMAone
 * @param {QpLoggerService} qpLoggerService The logger service
 * @returns {QpNgxQimaTranslationService} The translation service for ngx-qima
 */
export function qpNgxQimaTranslationFactory(
  qpLocaleService: QpLocaleService,
  qpLoggerService: QpLoggerService
): QpNgxQimaTranslationService {
  return new QpNgxQimaTranslationService(qpLocaleService, qpLoggerService);
}
