export enum EQpLocale {
  BANGLADESHI = 'bn',
  ENGLISH = 'en',
  ENGLISH_PRODUCE = 'en-produce',
  SPANISH = 'es',
  SPANISH_PRODUCE = 'es-produce',
  FRENCH = 'fr',
  GERMAN = 'de',
  JAPANESE = 'ja',
  KOREAN = 'ko',
  INDIAN = 'hi',
  PORTUGUESE = 'pt',
  THAI = 'ta',
  VIETNAMESE = 'vi',
  CHINESE = 'zh',
  EN_ACTION = 'en-action',
}

/**
 * @description
 * See {@link https://angular.io/api/common/DecimalPipe#digitsinfo}
 */
export interface IQpDigitsInfo {
  minIntegerDigits: number;
  minFractionDigits: number;
  maxFractionDigits: number;
}
