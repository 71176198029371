import { ErrorComponent } from '@admin/app/core/layouts/error/error.component';
import { Routes } from '@angular/router';

export const ERROR_ROUTE: Routes = [
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      authorities: [],
      pageTitle: 'error.title',
    },
  },
  {
    path: 'accessdenied',
    component: ErrorComponent,
    data: {
      authorities: [],
      pageTitle: 'error.title',
      error403: true,
    },
  },
  {
    path: '404',
    component: ErrorComponent,
    data: {
      authorities: [],
      pageTitle: 'error.title',
      error404: true,
    },
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
