<div>
  <div class="qp-row qp-justify-content-center">
    <div class="qp-col-md-8">
      <div *ngIf="success" class="qp-alert qp-alert-success">
        <strong>{{ 'settings.messages.success' | translate }}</strong>
      </div>

      <form #settingsForm="ngForm" (ngSubmit)="save()" *ngIf="settingsAccount" name="form" novalidate role="form">
        <div class="qp-form-group">
          <qp-label for="firstName">{{ 'settings.form.firstName.label' | translate }}</qp-label>
          <qp-text
            #firstNameInput="ngModel"
            [(ngModel)]="settingsAccount.firstName"
            [capitalize]="true"
            [qpError]="firstNameInput.dirty && firstNameInput.invalid"
            id="firstName"
            maxlength="50"
            minlength="1"
            name="firstName"
            placeholder="{{ 'settings.form.firstName.placeholder' | translate }}"
            required
          >
            <div *ngIf="firstNameInput.dirty && firstNameInput.invalid">
              <qp-label *ngIf="firstNameInput.errors.required" error="true" type="informative">
                {{ 'settings.messages.validate.firstName.required' | translate }}
              </qp-label>
              <qp-label *ngIf="firstNameInput.errors.minlength" error="true" type="informative">
                {{ 'settings.messages.validate.firstName.minlength' | translate }}
              </qp-label>
              <qp-label *ngIf="firstNameInput.errors.maxlength" error="true" type="informative">
                {{ 'settings.messages.validate.firstName.maxlength' | translate }}
              </qp-label>
            </div>
          </qp-text>
        </div>
        <div class="qp-form-group">
          <qp-label for="lastName">{{ 'settings.form.lastName.label' | translate }}</qp-label>
          <qp-text
            #lastNameInput="ngModel"
            [(ngModel)]="settingsAccount.lastName"
            [capitalize]="true"
            [qpError]="lastNameInput.dirty && lastNameInput.invalid"
            id="lastName"
            maxlength="50"
            minlength="1"
            name="lastName"
            placeholder="{{ 'settings.form.lastName.placeholder' | translate }}"
            required
          >
            <div *ngIf="lastNameInput.dirty && lastNameInput.invalid">
              <qp-label *ngIf="lastNameInput.errors.required" error="true" type="informative">
                {{ 'settings.messages.validate.lastName.required' | translate }}
              </qp-label>
              <qp-label *ngIf="lastNameInput.errors.minlength" error="true" type="informative">
                {{ 'settings.messages.validate.lastName.minlength' | translate }}
              </qp-label>
              <qp-label *ngIf="lastNameInput.errors.maxlength" error="true" type="informative">
                {{ 'settings.messages.validate.lastName.maxlength' | translate }}
              </qp-label>
            </div>
          </qp-text>
        </div>
        <div class="qp-form-group">
          <qp-label for="email" translate="">{{ 'global.form.email.label' | translate }}</qp-label>
          <qp-text
            #emailInput="ngModel"
            [(ngModel)]="settingsAccount.email"
            [qpError]="emailInput.dirty && emailInput.invalid"
            email
            id="email"
            maxlength="254"
            minlength="5"
            name="email"
            placeholder="{{ 'global.form.email.placeholder' | translate }}"
            required
            type="email"
          >
            <div *ngIf="emailInput.dirty && emailInput.invalid">
              <qp-label *ngIf="emailInput.errors.required" error="true" type="informative">
                {{ 'global.messages.validate.email.required' | translate }}
              </qp-label>
              <qp-label *ngIf="emailInput.errors.email" error="true" type="informative">
                {{ 'global.messages.validate.email.invalid' | translate }}
              </qp-label>
              <qp-label *ngIf="emailInput.errors.minlength" error="true" type="informative">
                {{ 'global.messages.validate.email.minlength' | translate }}
              </qp-label>
              <qp-label *ngIf="emailInput.errors.maxlength" error="true" type="informative">
                {{ 'global.messages.validate.email.maxlength' | translate }}
              </qp-label>
            </div>
          </qp-text>
        </div>
        <div *ngIf="languages && languages.length > 0" class="qp-form-group">
          <qp-label for="langKey">{{ 'settings.form.language' | translate }}</qp-label>
          <select [(ngModel)]="settingsAccount.langKey" class="qp-form-control" id="langKey" name="langKey">
            <option *ngFor="let language of languages" [value]="language">{{ language | qpFindLanguageFromKey }}</option>
          </select>
        </div>
        <button
          [disabled]="settingsForm.form.invalid"
          [qpButtonSize]="buttonSizes.MEDIUM"
          [qpButtonType]="buttonTypes.HERO_BLUE"
          qpButton
          type="submit"
        >
          {{ 'settings.form.button' | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
