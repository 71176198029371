import { Subject } from 'rxjs';
/**
 * QimaDrawerRef is a wrapper class used to interact with the QimaDrawerComponent.
 * It provides an abstract and safer way to control the drawer, such as the close method,
 * without exposing the entire QimaDrawerComponent.
 */
export class QimaDrawerRef {
  /**
   * QimaDrawerRef constructor
   * @param {QimaDrawerComponent} _qimaDrawerComponent - The injected instance of QimaDrawerComponent.
   */
  constructor(_qimaDrawerComponent) {
    this._qimaDrawerComponent = _qimaDrawerComponent;
    this._afterClosedSubject$ = new Subject();
  }
  close(result) {
    this._qimaDrawerComponent.onCloseClick();
    this._afterClosedSubject$.next(result);
    this._afterClosedSubject$.complete();
  }
  afterClosed$() {
    return this._afterClosedSubject$.asObservable();
  }
}
