<div>
  <div class="qp-row qp-justify-content-end">
    <div class="qp-col-md-8">
      <h1>{{ 'error.title' | translate }}</h1>
      <div [hidden]="!errorMessage">
        <div class="qp-alert qp-alert-danger">{{ errorMessage }}</div>
      </div>
      <div [hidden]="!error403" class="qp-alert qp-alert-danger">{{ 'error.http.403' | translate }}</div>
      <div [hidden]="!error404" class="qp-alert qp-alert-danger">{{ 'error.http.404' | translate }}</div>
    </div>
  </div>
</div>
