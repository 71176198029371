// @ts-strict-ignore
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { QimaOptionalType } from '@qima/ngx-qima';

/**
 * @param fieldA
 * @param fieldB
 */
export function qpEqualFieldsValidator(fieldA: Readonly<string>, fieldB: Readonly<string>): ValidatorFn {
  return (control: AbstractControl): QimaOptionalType<{ fieldsAreEqual: boolean }> =>
    control.get(fieldA).value === control.get(fieldB).value
      ? null
      : {
          fieldsAreEqual: true,
        };
}
