// @ts-strict-ignore
import { IQpPasswordStrengthBarStrength } from '@library/components/qp-password-strength-bar/qp-password-strength-bar.models';
import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'qp-password-strength-bar',
  templateUrl: './qp-password-strength-bar.component.html',
  styleUrls: ['./qp-password-strength-bar.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class QpPasswordStrengthBarComponent {
  public colors = ['#fb4b53', '#fb4b53', '#fab90c', '#00c48b', '#00c48b'];
  public strengthDescriptions = ['very-weak', 'weak', 'average', 'good', 'perfect'];
  public strength: IQpPasswordStrengthBarStrength;
  public passwordQuality: string;

  public constructor(
    private readonly _renderer: Renderer2,
    private readonly _elementRef: ElementRef
  ) {}

  @Input()
  public set passwordSecurityScore(score: number) {
    this.setStrengthBarColor(score);
  }

  public setStrengthBarColor(score: number): void {
    if (!score) {
      this.passwordQuality = undefined;
    }

    if (score || score === 0) {
      this.passwordQuality = this.strengthDescriptions[score];
      this.strength = { index: score + 1, color: this.colors[score] };
      const element = this._elementRef.nativeElement;

      if (element.className) {
        this._renderer.removeClass(element, element.className);
      }

      const lis = element.getElementsByTagName('li');
      const p = element.getElementsByTagName('p')[0];

      if (p) {
        this._renderer.setStyle(p, 'color', this.strength.color);
      }

      for (let i = 0; i < lis.length; i++) {
        if (i < this.strength.index) {
          this._renderer.setStyle(lis[i], 'backgroundColor', this.strength.color);
        } else {
          this._renderer.setStyle(lis[i], 'backgroundColor', '#DDD');
        }
      }
    }
  }
}
