import { Injectable } from '@angular/core';
import * as localForage from 'localforage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public saveEphemeralData(key: string, data: unknown): Promise<boolean> {
    sessionStorage.setItem(key, JSON.stringify(data));

    return Promise.resolve(true);
  }

  public getEphemeralData(key: string): Promise<unknown> {
    const storedObject = sessionStorage.getItem(key);

    if (storedObject) {
      return Promise.resolve(JSON.parse(storedObject));
    }

    return Promise.reject(`No object was found in storage for the key ${key}`);
  }

  public saveData(key: string, data: unknown): Promise<unknown> {
    return localForage.setItem(key, data);
  }

  public getData(key: string): Promise<unknown> {
    return localForage.getItem(key);
  }

  public clearData(): Promise<void> {
    return localForage.clear();
  }
}
