// @ts-strict-ignore
import { QpPasswordService } from '@library/classes/qp-password/qp-password.service';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { of, Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

/**
 * @param passwordService
 * @param minSecurityScore
 */
export function qpPasswordSecurityScoreValidator(
  passwordService: QpPasswordService,
  minSecurityScore: Readonly<number> = 2
): AsyncValidatorFn {
  const maxSecurityScore = 4;
  let safeMinSecurityScore = minSecurityScore;

  if (safeMinSecurityScore > maxSecurityScore) {
    safeMinSecurityScore = maxSecurityScore;
  }

  return (input: AbstractControl): Observable<ValidationErrors | null> => {
    if (!input.value) {
      return of({ score: -1 });
    }

    return of(null).pipe(
      switchMap((): Observable<ValidationErrors | unknown> => passwordService.getSecurityScore$(input.value)),
      map((res: ValidationErrors | unknown): ValidationErrors | null => {
        input['securityScore'] = res['score'];

        const passwordErrors = {
          passwordSecurityScore: {
            actualScore: res['score'],
            requiredScore: safeMinSecurityScore,
          },
        };

        return res['score'] >= safeMinSecurityScore ? null : passwordErrors;
      })
    );
  };
}
