// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'admin-error',
  templateUrl: './error.component.html',
  standalone: true,
  imports: [TranslateModule],
})
export class ErrorComponent implements OnInit {
  public errorMessage: string;
  public error403: boolean;
  public error404: boolean;

  public constructor(private readonly _activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    this._activatedRoute.data.subscribe((routeData): void => {
      if (routeData.error403) {
        this.error403 = routeData.error403;
      }

      if (routeData.error404) {
        this.error404 = routeData.error404;
      }

      if (routeData.errorMessage) {
        this.errorMessage = routeData.errorMessage;
      }
    });
  }
}
