import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { QpAlertErrorService } from '@library/services/qp-alert-error/qp-alert-error.service';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  public constructor(private readonly _qpAlertErrorService: QpAlertErrorService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (err: unknown): void => {
          if (err instanceof HttpErrorResponse) {
            if (!(err.status === EQpHttpStatusCode.UNAUTHORIZED && (err.message === '' || err.url?.includes('/api/account')))) {
              this._qpAlertErrorService.handleError(err);
            }
          }
        },
      })
    );
  }
}
