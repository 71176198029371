import { ChangelogComponent } from '@admin/app/account/changelog/changelog.component';
import { DocsComponent } from '@admin/app/account/docs/docs.component';
import { PasswordComponent } from '@admin/app/account/password/password.component';
import { PasswordResetFinishComponent } from '@admin/app/account/password-reset/finish/password-reset-finish.component';
import { PasswordResetInitComponent } from '@admin/app/account/password-reset/init/password-reset-init.component';
import { SettingsComponent } from '@admin/app/account/settings/settings.component';
import { IS_DEBUG_INFO_ENABLED } from '@admin/app/app.constants';
import { AuthoritiesGuard } from '@admin/app/core/auth/authorities.guard';
import { ERROR_ROUTE } from '@admin/app/core/layouts/error/error.route';
import { HomeComponent } from '@admin/app/home/home.component';
import { LoginComponent } from '@admin/app/login/login.component';
import { EQpAuthority } from '@library/models/rights/qp-authority.models';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const ROUTES: Routes = [
  {
    path: 'login',
    data: {
      pageTitle: 'login.title',
      isMenuHidden: true,
    },
    component: LoginComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: {
      pageTitle: 'global.menu.settings',
      isMenuHidden: false,
    },
    canActivate: [AuthoritiesGuard],
  },
  {
    path: 'reset/finish',
    component: PasswordResetFinishComponent,
    data: {
      authorities: [],
      pageTitle: 'global.menu.account.password',
    },
  },
  {
    path: 'reset/request',
    component: PasswordResetInitComponent,
    data: {
      authorities: [],
      pageTitle: 'global.menu.account.password',
    },
  },
  {
    path: 'password',
    component: PasswordComponent,
    data: {
      authorities: [EQpAuthority.ROLE_ADMIN],
      pageTitle: 'global.menu.account.password',
    },
    canActivate: [AuthoritiesGuard],
  },
  {
    path: 'changelog',
    component: ChangelogComponent,
    data: {
      authorities: [EQpAuthority.ROLE_ADMIN],
      pageTitle: 'global.menu.account.changelog',
    },
    canActivate: [AuthoritiesGuard],
  },
  {
    path: 'swagger',
    component: DocsComponent,
    data: {
      authorities: [EQpAuthority.ROLE_ADMIN],
      pageTitle: 'global.menu.account.swagger',
    },
    canActivate: [AuthoritiesGuard],
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthoritiesGuard],
    data: {
      authorities: [EQpAuthority.ROLE_ADMIN],
      pageTitle: 'home.title',
    },
  },
  {
    path: 'user-management',
    loadChildren: () => import('./user-management/user-management.module').then(m => m.UserMgmtModule),
  },
  {
    path: 'reports-management',
    loadChildren: () => import('./reports-management/reports-management.module').then(m => m.ReportsManagementModule),
  },
  {
    path: 'brand-management',
    loadChildren: () => import('./brand-management/brand-management.module').then(m => m.BrandManagementModule),
  },
  {
    path: 'inspections-management',
    loadChildren: () => import('./inspections-management/inspections-management.module').then(m => m.InspectionsManagementModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot([...ROUTES, ...ERROR_ROUTE], {
      useHash: true,
      enableTracing: IS_DEBUG_INFO_ENABLED,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
