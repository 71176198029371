import { EQimaDrawerPosition } from '../enums/qima-drawer-position.enum';
import { InjectionToken } from '@angular/core';
/**
 * @description
 * The token consumed by the {@link QimaDrawerComponent} to define the default values of its inputs.
 * @type {InjectionToken<IQimaDrawerConfig>}
 */
export const QIMA_DRAWER_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the drawer component', {
  factory() {
    return {
      hasBackdrop: true,
      isBackdropCloseable: true,
      position: EQimaDrawerPosition.RIGHT,
      containerId: ''
    };
  }
});
