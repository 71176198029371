<div>
  <div class="qp-row qp-justify-content-center">
    <div class="qp-col-md-8">
      <h1>{{ 'reset.request.title' | translate }}</h1>

      <div *ngIf="errorEmailNotExists" class="qp-alert qp-alert-danger">
        <strong>{{ 'reset.request.messages.not-found' | translate }}</strong>
        {{ 'reset.request.messages.try-again' | translate }}
      </div>

      <div *ngIf="!success" class="qp-alert qp-alert-warning">
        <p>{{ 'reset.request.messages.info' | translate }}</p>
      </div>

      <div *ngIf="success === 'OK'" class="qp-alert qp-alert-success">
        <p>{{ 'reset.request.messages.success' | translate }}</p>
      </div>

      <form #resetRequestForm="ngForm" (ngSubmit)="requestReset()" *ngIf="!success" name="form" role="form">
        <div class="qp-form-group">
          <label for="email">{{ 'global.form.email.label' | translate }} </label>
          <input
            #emailInput="ngModel"
            [(ngModel)]="resetAccount.email"
            class="qp-form-control"
            email
            id="email"
            maxlength="254"
            minlength="5"
            name="email"
            placeholder="{{ 'global.form.email.placeholder' | translate }}"
            required
            type="email"
          />
          <div *ngIf="emailInput.dirty && emailInput.invalid">
            <small *ngIf="emailInput.errors.required" class="qp-form-text qp-text-danger">
              {{ 'global.messages.validate.email.required' | translate }}
            </small>
            <small *ngIf="emailInput.errors.email" class="qp-form-text qp-text-danger">
              {{ 'global.messages.validate.email.invalid' | translate }}
            </small>
            <small *ngIf="emailInput.errors.minlength" class="qp-form-text qp-text-danger">
              {{ 'global.messages.validate.email.minlength' | translate }}
            </small>
            <small *ngIf="emailInput.errors.maxlength" class="qp-form-text qp-text-danger">
              {{ 'global.messages.validate.email.maxlength' | translate }}
            </small>
          </div>
        </div>
        <button [disabled]="resetRequestForm.form.invalid" class="qp-btn qp-btn-primary" type="submit">
          {{ 'reset.request.form.button' | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
