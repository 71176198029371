// @ts-strict-ignore
import { PasswordResetInitService } from '@admin/app/account/password-reset/init/password-reset-init.service';
import { NgIf } from '@angular/common';
import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'admin-password-reset-init',
  templateUrl: './password-reset-init.component.html',
  styleUrls: ['./password-reset-init.component.scss'],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FormsModule, TranslateModule],
})
export class PasswordResetInitComponent implements OnInit, AfterViewInit {
  public error: string;
  public errorEmailNotExists: string;
  public resetAccount: QimaOptionalType<{
    email?: string;
  }>;

  public success: string;

  public constructor(
    private readonly _passwordResetInitService: PasswordResetInitService,
    private readonly _elementRef: ElementRef
  ) {}

  public ngOnInit(): void {
    this.resetAccount = {};
  }

  public ngAfterViewInit(): void {
    this._elementRef.nativeElement.querySelector('#email').focus();
  }

  public requestReset(): void {
    this.error = null;
    this.errorEmailNotExists = null;

    this._passwordResetInitService.save(this.resetAccount.email).subscribe(
      (): void => {
        this.success = 'OK';
      },
      (): void => {
        this.success = null;
        this.error = 'ERROR';
      }
    );
  }
}
