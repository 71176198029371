import { EQpLocale } from '@library/models/i18n/qp-locales.models';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';

/**
 * @description
 * Internal service
 * Exceptionally provided in root
 */
@Injectable({
  providedIn: 'root',
})
export class QpLocaleLoaderService {
  /*
   * dynamic import of locales worked with webpack magic comments but,
   * moving on to esbuild, esbuild necessitates that imports be statically analyzed.
   * not the best solution but it works since we don't have a lot of locales to load
   * 💡https://github.com/angular/angular-cli/issues/26904#issuecomment-1903596563
   */
  private readonly localeMap = {
    bn: (): Promise<typeof import('@angular/common/locales/bn')> => import('@angular/common/locales/bn'),
    es: (): Promise<typeof import('@angular/common/locales/es')> => import('@angular/common/locales/es'),
    fr: (): Promise<typeof import('@angular/common/locales/fr')> => import('@angular/common/locales/fr'),
    de: (): Promise<typeof import('@angular/common/locales/de')> => import('@angular/common/locales/de'),
    ja: (): Promise<typeof import('@angular/common/locales/ja')> => import('@angular/common/locales/ja'),
    ko: (): Promise<typeof import('@angular/common/locales/ko')> => import('@angular/common/locales/ko'),
    hi: (): Promise<typeof import('@angular/common/locales/hi')> => import('@angular/common/locales/hi'),
    pt: (): Promise<typeof import('@angular/common/locales/pt')> => import('@angular/common/locales/pt'),
    ta: (): Promise<typeof import('@angular/common/locales/ta')> => import('@angular/common/locales/ta'),
    vi: (): Promise<typeof import('@angular/common/locales/vi')> => import('@angular/common/locales/vi'),
    zh: (): Promise<typeof import('@angular/common/locales/zh')> => import('@angular/common/locales/zh'),
    // Add other locales as needed
  };

  private readonly _localeFileLoader: { [key in EQpLocale]: () => Promise<void> } = {
    [EQpLocale.BANGLADESHI]: (): Promise<void> => {
      return this._loadLocale('bn');
    },
    [EQpLocale.ENGLISH](): Promise<void> {
      // Angular embed by default the `en` locale so no need to load it once again
      return Promise.resolve();
    },
    [EQpLocale.ENGLISH_PRODUCE](): Promise<void> {
      // Angular embed by default the `en` locale so no need to load it once again
      return Promise.resolve();
    },
    [EQpLocale.SPANISH]: (): Promise<void> => {
      return this._loadLocale('es');
    },
    [EQpLocale.SPANISH_PRODUCE]: (): Promise<void> => {
      return this._loadLocale('es');
    },
    [EQpLocale.FRENCH]: (): Promise<void> => {
      return this._loadLocale('fr');
    },
    [EQpLocale.GERMAN]: (): Promise<void> => {
      return this._loadLocale('de');
    },
    [EQpLocale.JAPANESE]: (): Promise<void> => {
      return this._loadLocale('ja');
    },
    [EQpLocale.KOREAN]: (): Promise<void> => {
      return this._loadLocale('ko');
    },
    [EQpLocale.INDIAN]: (): Promise<void> => {
      return this._loadLocale('hi');
    },
    [EQpLocale.PORTUGUESE]: (): Promise<void> => {
      return this._loadLocale('pt');
    },
    [EQpLocale.THAI]: (): Promise<void> => {
      return this._loadLocale('ta');
    },
    [EQpLocale.VIETNAMESE]: (): Promise<void> => {
      return this._loadLocale('vi');
    },
    [EQpLocale.CHINESE]: (): Promise<void> => {
      return this._loadLocale('zh');
    },
    [EQpLocale.EN_ACTION](): Promise<void> {
      return Promise.resolve();
    },
  };

  public constructor(private readonly _qpLoggerService: QpLoggerService) {}

  public async loadFile(localeId: Readonly<EQpLocale>): Promise<void> {
    return this._localeFileLoader[localeId]();
  }

  private _loadLocale(locale: string): Promise<void> {
    const loadLocale = this.localeMap[locale];

    if (!loadLocale) {
      this._qpLoggerService.warn(`Locale ${locale} not supported. Falling back to default locale.`);

      return Promise.resolve();
    }

    return loadLocale()
      .then((module): void => {
        registerLocaleData(module.default);
      })
      .catch((error): void => {
        this._qpLoggerService.error(`Failed to load locale: ${locale}`, error);
      });
  }
}
