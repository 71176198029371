// @ts-strict-ignore
import { StateStorageService } from '@admin/app/core/auth/state-storage.service';
import { LoginService } from '@admin/app/login/login.service';
import { EHttpStatusCode } from '@admin/app/shared/constants/http-status-code.constants';
import { QpCheckboxComponent } from '@library/components/qp-checkbox/qp-checkbox.component';
import { QpLabelComponent } from '@library/components/qp-label/qp-label.component';
import { QpTextComponent } from '@library/components/qp-text/qp-text.component';
import { EQpAutocompleteValue } from '@library/directives/qp-autocomplete/qp-autocomplete.models';
import { QpButtonDirective, EQpButtonDirectiveSize, EQpButtonDirectiveType } from '@library/directives/qp-button/qp-button.directive';
import { PlatformLocation, NgIf, NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'qp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, QpLabelComponent, QpTextComponent, NgClass, QpCheckboxComponent, QpButtonDirective, TranslateModule],
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public hasAuthenticationError = false;
  public password: string;
  public email: string;
  public credentials: unknown;
  public loginErrorStatus: number;
  public isIcpNumberHasToBeDisplayed = false;
  public readonly buttonSizes: typeof EQpButtonDirectiveSize = EQpButtonDirectiveSize;
  public readonly buttonTypes: typeof EQpButtonDirectiveType = EQpButtonDirectiveType;
  public readonly httpStatusCodes = EHttpStatusCode;
  public readonly autocompleteValues = EQpAutocompleteValue;

  public constructor(
    private readonly _platformLocation: PlatformLocation,
    private readonly _loginService: LoginService,
    private readonly _stateStorageService: StateStorageService,
    private readonly _router: Router
  ) {}

  public ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
      isRemembered: new UntypedFormControl(false, Validators.required),
    });
    this.isIcpNumberHasToBeDisplayed = this._platformLocation.hostname.toUpperCase().endsWith('.CN');
  }

  public login(): void {
    this._loginService
      .login({
        email: this.loginForm.get('email').value.trim(),
        password: this.loginForm.get('password').value,
        rememberMe: this.loginForm.get('isRemembered').value,
      })
      .then((): void => {
        this.hasAuthenticationError = false;

        if (this._router.url === '/register' || /^\/activate\//.test(this._router.url) || /^\/reset\//.test(this._router.url)) {
          void this._router.navigate(['']);
        }

        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
        // since login is successful, go to stored previousState and clear previousState
        const redirect = this._stateStorageService.getUrl();

        if (redirect && redirect !== 'login') {
          this._stateStorageService.storeUrl(null);
          void this._router.navigate([redirect]);
        } else if (!redirect || redirect === 'login') {
          this._stateStorageService.storeUrl(null);
          void this._router.navigate(['']);
        }
      })
      .catch((error: HttpErrorResponse): void => {
        this.hasAuthenticationError = true;
        this.loginErrorStatus = error.status;
      });
  }

  public requestResetPassword(): void {
    void this._router.navigate(['/reset', 'request']);
  }
}
