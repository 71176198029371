import { HttpUrlEncodingCodec } from '@angular/common/http';

export class QpHttpEncoder extends HttpUrlEncodingCodec {
  public encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  public encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
}
