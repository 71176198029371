import { SERVER_API_URL } from '@admin/app/app.constants';
import { QpBrandTypeService } from '@library/components/qp-brand-type/services/qp-brand-type.service';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandTypeService implements QpBrandTypeService {
  private readonly _resourceUrl = `${SERVER_API_URL}api/admin/brands/types`;

  public constructor(
    protected _httpClient: HttpClient,
    private readonly _qpNotificationBarService: QpNotificationBarService
  ) {}

  public getAllBrandTypes$(): Observable<string[]> {
    const brandTypesSubject$ = new ReplaySubject<string[]>(1);

    this._httpClient.get<string[]>(this._resourceUrl, { observe: 'body' }).subscribe(
      (types: string[]): void => {
        brandTypesSubject$.next(types);
      },
      (): void => {
        this._qpNotificationBarService.error('error.no-brand-types-available');
      }
    );

    return brandTypesSubject$.asObservable();
  }
}
