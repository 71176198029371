import { EQpLocale } from '@library/models/i18n/qp-locales.models';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    Title,
    {
      provide: LOCALE_ID,
      useValue: EQpLocale.ENGLISH,
    },
    DatePipe,
  ],
})
export class AdminCoreModule {}
