// @ts-strict-ignore
import { LoginModalComponent } from '@admin/app/shared/login/login.component';
import { QpModalService } from '@library/services/qp-modal/qp-modal.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoginModalService {
  private _isOpen = false;

  public constructor(private readonly _modalService: QpModalService) {}

  public open(): void {
    if (this._isOpen) {
      return null;
    }

    this._isOpen = true;

    const modalRef = this._modalService.open(LoginModalComponent);

    modalRef.componentRef.instance.afterClosed$.subscribe((): void => {
      modalRef.close();
      this._isOpen = false;
    });
  }
}
