<div>
  <div class="qp-row qp-justify-content-center">
    <div class="qp-col-md-8">
      <div *ngIf="success" class="qp-alert qp-alert-success">
        <strong>{{ 'password.messages.success' | translate }}</strong>
      </div>
      <div *ngIf="error" class="qp-alert qp-alert-danger">
        {{ 'password.messages.error' | translate }}
      </div>
      <form (ngSubmit)="changePassword()" [formGroup]="passwordFormGroup" name="form" role="form">
        <div class="qp-form-group">
          <qp-label>{{ 'global.form.currentpassword.label' | translate }} </qp-label>
          <qp-text
            [qpError]="currentPasswordField.dirty && currentPasswordField.invalid && currentPasswordField.errors.required"
            formControlName="currentPassword"
            placeholder="{{ 'global.form.currentpassword.placeholder' | translate }}"
            type="password"
          >
            <qp-label error="true" type="informative">
              {{ 'global.messages.validate.newpassword.required' | translate }}
            </qp-label>
          </qp-text>
        </div>
        <qp-password-field (linkToParent)="linkFormGroup($event)"></qp-password-field>
        <button
          [disabled]="passwordFormGroup.invalid"
          [qpButtonSize]="buttonSizes.MEDIUM"
          [qpButtonType]="buttonTypes.HERO_BLUE"
          qpButton
          type="submit"
        >
          {{ 'password.form.button' | translate }}
        </button>
      </form>
    </div>
  </div>
</div>
