import { SERVER_API_URL } from '@admin/app/app.constants';
import { QpPasswordService } from '@library/classes/qp-password/qp-password.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PasswordService implements QpPasswordService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public save$(newPassword: string, currentPassword: string): Observable<unknown> {
    return this._httpClient.post(`${SERVER_API_URL}api/account/change-password`, { currentPassword, newPassword });
  }

  public getSecurityScore$(password: Readonly<string>): Observable<unknown> {
    return this._httpClient.post(`${SERVER_API_URL}/api/passwords/score`, { password });
  }

  public getPasswordRules$(): Observable<unknown> {
    return this._httpClient.get(`${SERVER_API_URL}/api/passwords/rules`, { observe: 'body' });
  }
}
