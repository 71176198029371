// @ts-strict-ignore
import { AccountService } from '@admin/app/core/auth/account.service';
import { Account } from '@admin/app/core/user/account.model';
import { DisplayedRoute, IDisplayedRoute } from '@admin/app/shared/service/route/displayed-route';
import { EQpAuthority } from '@library/models/rights/qp-authority.models';
import { Injectable } from '@angular/core';
import { intersection } from 'lodash/index';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum EMenuType {
  INSPECTIONS = 'INSPECTIONS',
  PRODUCTS = 'PRODUCTS',
  FACTORIES = 'FACTORIES',
  TEAMS = 'TEAMS',
  DASHBOARD = 'DASHBOARD',
  CHECKLISTS = 'CHECKLISTS',
  SETTINGS = 'SETTINGS',
}

export enum EMenuPosition {
  COLLAPSE = 'COLLAPSE',
  EXPAND = 'EXPAND',
}

@Injectable({
  providedIn: 'root',
})
export class DisplayedRoutesService {
  public static readonly settingsDropdownValues = [
    DisplayedRoute.create({
      title: 'global.menu.account.main',
      routerLinkActiveOption: { exact: true },
      routerLink: 'settings',
      authorities: [EQpAuthority.ROLE_ADMIN],
      isDefaultPage: true,
      dataCy: 'settings-nav-dropdown-button',
    }),
    DisplayedRoute.create({
      title: 'global.menu.account.password',
      routerLinkActiveOption: { exact: true },
      routerLink: 'password',
      authorities: [EQpAuthority.ROLE_ADMIN],
      dataCy: 'password-nav-dropdown-button',
    }),
    DisplayedRoute.create({
      title: 'global.menu.account.changelog',
      routerLinkActiveOption: { exact: true },
      routerLink: 'changelog',
      authorities: [EQpAuthority.ROLE_ADMIN],
      dataCy: 'changelog-nav-dropdown-button',
    }),
    DisplayedRoute.create({
      title: 'global.menu.account.swagger',
      routerLinkActiveOption: { exact: true },
      routerLink: 'swagger',
      authorities: [EQpAuthority.ROLE_ADMIN],
      dataCy: 'swagger-nav-dropdown-button',
    }),
  ];

  public static readonly userManagement = [
    DisplayedRoute.create({
      title: 'userManagement.home.management',
      routerLinkActiveOption: { exact: true },
      routerLink: 'user-management',
      authorities: [EQpAuthority.ROLE_ADMIN],
      iconName: 'ic-myteam',
      isDefaultPage: true,
      dataCy: 'home-users-mgmt',
    }),
    DisplayedRoute.create({
      title: 'userManagement.home.createLabel',
      routerLinkActiveOption: { exact: true },
      routerLink: 'user-management/new',
      isDefaultAction: true,
      authorities: [EQpAuthority.ROLE_ADMIN],
      iconName: 'ic-myteam',
      dataCy: 'home-users-new',
    }),
  ];

  public static readonly brandManagement = [
    DisplayedRoute.create({
      title: 'brandManagement.home.title',
      routerLinkActiveOption: { exact: true },
      routerLink: 'brand-management',
      authorities: [EQpAuthority.ROLE_ADMIN],
      isDefaultPage: true,
      dataCy: 'home-users-mgmt',
    }),
    DisplayedRoute.create({
      title: 'brandManagement.home.createLabel',
      routerLinkActiveOption: { exact: true },
      routerLink: 'brand-management/new',
      isDefaultAction: true,
      authorities: [EQpAuthority.ROLE_ADMIN],
      dataCy: 'home-users-new',
    }),
  ];

  public static readonly reportsManagement = [
    DisplayedRoute.create({
      title: 'reportsManagement.actions.title',
      routerLinkActiveOption: { exact: true },
      routerLink: 'reports-management/actions',
      authorities: [EQpAuthority.ROLE_ADMIN],
      iconName: 'ic-report',
      dataCy: 'reports-nav-button',
    }),
  ];

  public static readonly inspectionsManagement = [
    DisplayedRoute.create({
      title: 'inspectionsManagement.actions.title',
      routerLinkActiveOption: { exact: true },
      routerLink: 'inspections-management/actions',
      authorities: [EQpAuthority.ROLE_ADMIN],
      iconName: 'ic-assignments',
      dataCy: 'inspections-nav-button',
    }),
  ];

  public static readonly mainMenuValues = [
    DisplayedRoute.create({
      title: 'home.navigation-menu.home-menu',
      routerLinkActiveOption: { exact: true },
      routerLink: '',
      authorities: [EQpAuthority.ROLE_ADMIN],
      iconName: 'ic-home',
      dataCy: 'home-nav-button',
    }),
    DisplayedRoute.create({
      title: 'userManagement.home.management',
      routerLinkActiveOption: { exact: true },
      routerLink: '',
      isSecondaryMenu: true,
      children: DisplayedRoutesService.userManagement,
      authorities: [EQpAuthority.ROLE_ADMIN],
      iconName: 'ic-myteam',
      dataCy: 'home-users',
    }),
    DisplayedRoute.create({
      title: 'brandManagement.home.title',
      routerLinkActiveOption: { exact: true },
      routerLink: '',
      isSecondaryMenu: true,
      children: DisplayedRoutesService.brandManagement,
      authorities: [EQpAuthority.ROLE_ADMIN],
      iconName: 'ic-factories',
      dataCy: 'home-users',
    }),
    DisplayedRoute.create({
      title: 'reportsManagement.home.title',
      routerLinkActiveOption: { exact: true },
      routerLink: '',
      isSecondaryMenu: true,
      children: DisplayedRoutesService.reportsManagement,
      authorities: [EQpAuthority.ROLE_ADMIN],
      iconName: 'ic-report',
      dataCy: 'home-reports',
    }),
    DisplayedRoute.create({
      title: 'inspectionsManagement.home.title',
      routerLinkActiveOption: { exact: true },
      routerLink: '',
      isSecondaryMenu: true,
      children: DisplayedRoutesService.inspectionsManagement,
      authorities: [EQpAuthority.ROLE_ADMIN],
      iconName: 'ic-assignments',
      dataCy: 'home-inspections',
    }),
    DisplayedRoute.create({
      title: 'global.menu.account.settings',
      routerLinkActiveOption: { exact: false },
      routerLink: '',
      authorities: null,
      children: DisplayedRoutesService.settingsDropdownValues,
      iconName: 'ic-settings',
      isSecondaryMenu: true,
      dataCy: 'settings-nav-button',
    }),
  ];

  public menuPosition = new BehaviorSubject<EMenuPosition>(EMenuPosition.COLLAPSE);

  public constructor(private readonly _accountService: AccountService) {}

  public getRoutes(): Observable<IDisplayedRoute[]> {
    return this._accountService.getAuthenticationState$().pipe(
      map((account): IDisplayedRoute[] => {
        if (account) {
          return this._filterRoutesByAuthorities(account);
        }

        return [];
      })
    );
  }

  public setDefaultPageSettings(mainMenu: IDisplayedRoute): IDisplayedRoute {
    if (mainMenu.children && mainMenu.children.length > 0) {
      const defaultSubMenu = mainMenu.children.find((subMenu: IDisplayedRoute): boolean => subMenu.isDefaultPage);

      if (defaultSubMenu) {
        mainMenu.defaultPageRoute = DisplayedRoute.create({
          title: defaultSubMenu.title,
          routerLinkActiveOption: defaultSubMenu.routerLinkActiveOption,
          routerLink: defaultSubMenu.routerLink,
          authorities: defaultSubMenu.authorities,
          iconName: defaultSubMenu.iconName,
          dataCy: defaultSubMenu.dataCy,
        });
      } else {
        mainMenu.defaultPageRoute = DisplayedRoute.create({
          title: mainMenu.children[0].title,
          routerLinkActiveOption: mainMenu.children[0].routerLinkActiveOption,
          routerLink: mainMenu.children[0].routerLink,
          authorities: mainMenu.children[0].authorities,
          iconName: mainMenu.children[0].iconName,
          dataCy: mainMenu.dataCy,
        });
      }
    }

    return mainMenu;
  }

  public setDefaultActionSettings(mainMenu: IDisplayedRoute): IDisplayedRoute {
    if (mainMenu.children && mainMenu.children.length > 0) {
      const defaultSubMenu = mainMenu.children.find((subMenu: IDisplayedRoute): boolean => subMenu.isDefaultAction);

      if (defaultSubMenu) {
        mainMenu.defaultActionRoute = DisplayedRoute.create({
          title: defaultSubMenu.title,
          routerLinkActiveOption: defaultSubMenu.routerLinkActiveOption,
          routerLink: defaultSubMenu.routerLink,
          authorities: defaultSubMenu.authorities,
          iconName: defaultSubMenu.iconName,
          dataCy: defaultSubMenu.dataCy,
        });
      }
    }

    return mainMenu;
  }

  private _filterRoutesByAuthorities(account: Account): IDisplayedRoute[] {
    let mainMenuList = DisplayedRoutesService.mainMenuValues.map((mainMenu: IDisplayedRoute): IDisplayedRoute => {
      let currentMainMenu: IDisplayedRoute = Object.assign({}, mainMenu);

      if (currentMainMenu.children) {
        currentMainMenu.children = currentMainMenu.children.filter(
          (route): boolean => intersection(account.profiles, route.authorities).length > 0
        );

        if (currentMainMenu.children.length > 0) {
          currentMainMenu.authorities = account.profiles;
        }
      }

      currentMainMenu = this.setDefaultPageSettings(currentMainMenu);

      return this.setDefaultActionSettings(currentMainMenu);
    });

    mainMenuList = mainMenuList.filter((route): boolean => intersection(account.profiles, route.authorities).length > 0);

    return mainMenuList;
  }
}
