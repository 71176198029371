import { AccountService } from '@admin/app/core/auth/account.service';
import { LanguageHelperService } from '@admin/app/core/language/language.helper';
import { DisplayedRoutesService, EMenuPosition } from '@admin/app/shared/service/route/displayed-routes.service';
import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, NavigationError, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public title = 'admin';
  public currentMenuPosition = EMenuPosition.COLLAPSE;
  public menuPositions = EMenuPosition;
  public routerUrl: string = '';
  public isMenuHidden = false;

  public constructor(
    private readonly _languageHelperService: LanguageHelperService,
    private readonly _router: Router,
    private readonly _accountService: AccountService,
    private readonly _displayedRoutesService: DisplayedRoutesService
  ) {}

  public findRouterData(routeSnapshot: ActivatedRouteSnapshot | null, dataKey: string): boolean {
    if (routeSnapshot && Object.keys(routeSnapshot.data).length > 0 && routeSnapshot.data[dataKey] !== undefined) {
      return routeSnapshot.data[dataKey];
    } else if (routeSnapshot?.firstChild) {
      return this.findRouterData(routeSnapshot.firstChild, dataKey);
    }

    return false;
  }

  public ngOnInit(): void {
    this._router.events.pipe(untilDestroyed(this)).subscribe((): void => {
      this.routerUrl = this._router.url;
    });

    this._router.events.pipe(untilDestroyed(this)).subscribe((event): void => {
      if (event instanceof NavigationEnd) {
        this._languageHelperService.updateTitle(this._getPageTitle(this._router.routerState.snapshot.root));
        this.isMenuHidden = this.findRouterData(this._router.routerState.snapshot.root.firstChild, 'isMenuHidden') || false;
      }

      if (event instanceof NavigationError && event.error.status === EQpHttpStatusCode.NOT_FOUND) {
        void this._router.navigate(['/error/404'], { replaceUrl: true });
      }
    });

    this._displayedRoutesService.menuPosition.pipe(untilDestroyed(this)).subscribe((menuPosition: EMenuPosition): void => {
      this.currentMenuPosition = menuPosition;
    });
  }

  public isAuthenticated(): boolean {
    return this._accountService.isAuthenticated();
  }

  private _getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = routeSnapshot.data?.pageTitle ? routeSnapshot.data.pageTitle : 'Admin Platform';

    if (routeSnapshot.firstChild) {
      title = this._getPageTitle(routeSnapshot.firstChild) || title;
    }

    return title;
  }
}
