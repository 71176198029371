import { AdminAppModule } from '@admin/app/app.module';
import { ENVIRONMENT } from '@admin/environments/environment';
import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (ENVIRONMENT.isProduction) {
  enableProdMode();
}

/**
 * @description
 * It is not too late to run.
 */
function bootstrap(): Promise<NgModuleRef<AdminAppModule>> {
  return platformBrowserDynamic().bootstrapModule(AdminAppModule, { preserveWhitespaces: true });
}

bootstrap().catch((error: unknown): void => {
  console.error(error);
});
