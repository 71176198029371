import { LanguageHelperService } from '@admin/app/core/language/language.helper';
import { QimaDesktopMainNavbarComponent } from '@admin/app/layouts/navbar/qima-desktop-main-navbar/qima-desktop-main-navbar.component';
import { QimaSubnavComponent } from '@admin/app/layouts/navbar/qima-subnav/qima-subnav.component';
import { AdminSharedLibsModule } from '@admin/app/shared/shared-libs.module';
import { AdminSharedModule } from '@admin/app/shared/shared.module';
import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { QpButtonDirective } from '@library/directives/qp-button/qp-button.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';

@NgModule({
  imports: [
    CommonModule,
    NzMenuModule,
    NzDrawerModule,
    AdminSharedLibsModule,
    AdminSharedModule,
    RouterModule,
    NzIconModule,
    TranslateModule,
    QpButtonDirective,
    QpIconComponent,
    QimaDesktopMainNavbarComponent,
    QimaSubnavComponent,
  ],
  exports: [QimaDesktopMainNavbarComponent, QimaSubnavComponent],
})
export class LayoutsModule {
  public constructor(translate: TranslateService, langageHelper: LanguageHelperService) {
    langageHelper.language.subscribe((langKey): void => {
      translate.use(langKey);
    });
  }
}
