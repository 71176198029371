<div id="strength" [style.justifyContent]="!passwordQuality ? 'flex-end' : 'space-between'">
  <p [hidden]="!passwordQuality">{{ 'global.form.newpassword.strength.' + passwordQuality | translate }}</p>
  <ul id="strengthBar">
    <li class="point"></li>
    <li class="point"></li>
    <li class="point"></li>
    <li class="point"></li>
    <li class="point"></li>
  </ul>
</div>
