import { AccountModule } from '@admin/app/account/account.module';
import { PasswordService } from '@admin/app/account/password/password.service';
import { PasswordResetFinishComponent } from '@admin/app/account/password-reset/finish/password-reset-finish.component';
import { PasswordResetInitComponent } from '@admin/app/account/password-reset/init/password-reset-init.component';
import { AdminRoutingModule } from '@admin/app/admin-routing.module';
import { AppComponent } from '@admin/app/app.component';
import { AuthExpiredInterceptor } from '@admin/app/blocks/interceptor/auth-expired.interceptor';
import { AuthInterceptor } from '@admin/app/blocks/interceptor/auth.interceptor';
import { ErrorHandlerInterceptor } from '@admin/app/blocks/interceptor/error-handler.interceptor';
import { AdminCoreModule } from '@admin/app/core/core.module';
import { ErrorComponent } from '@admin/app/core/layouts/error/error.component';
import { HomeComponent } from '@admin/app/home/home.component';
import { LayoutsModule } from '@admin/app/layouts/layouts.module';
import { LoginComponent } from '@admin/app/login/login.component';
import { ReportsManagementModule } from '@admin/app/reports-management/reports-management.module';
import { BrandTypeService } from '@admin/app/shared/service/brand/brand-type.service';
import { AddressService } from '@admin/app/shared/service/location/address.service';
import { CountryService } from '@admin/app/shared/service/location/country.service';
import { AdminEntitiesModule } from '@admin/app/shared/shared-entities.module';
import { AdminSharedLibsModule, httpLoaderFactory } from '@admin/app/shared/shared-libs.module';
import { AdminSharedModule } from '@admin/app/shared/shared.module';
import { UserMgmtModule } from '@admin/app/user-management/user-management.module';
import { QpPasswordService } from '@library/classes/qp-password/qp-password.service';
import { QpAddressService } from '@library/components/qp-address/services/qp-address.service';
import { QpBrandTypeService } from '@library/components/qp-brand-type/services/qp-brand-type.service';
import { QpCheckboxComponent } from '@library/components/qp-checkbox/qp-checkbox.component';
import { QpCountryService } from '@library/components/qp-country/services/qp-country.service';
import { QpItemCountComponent } from '@library/components/qp-item-count/qp-item-count.component';
import { QpLabelComponent } from '@library/components/qp-label/qp-label.component';
import { QpPasswordStrengthBarComponent } from '@library/components/qp-password-strength-bar/qp-password-strength-bar.component';
import { QpTextComponent } from '@library/components/qp-text/qp-text.component';
import { QpButtonDirective } from '@library/directives/qp-button/qp-button.directive';
import { QpHttpParamsInterceptor } from '@library/interceptors/qp-http-params/qp-http-params.interceptor';
import { EQpLocale } from '@library/models/i18n/qp-locales.models';
import { QP_NGX_QIMA_TRANSLATION_PROVIDER } from '@library/providers/qp-ngx-qima-translation.provider';
import { QpNotificationBarModule } from '@library/services/qp-notification-bar/qp-notification-bar.module';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { ClassProvider, NgModule, ValueProvider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { EQimaLoggerLevel, QIMA_ASSETS_PATH_TOKEN, QimaLoggerModule, QimaModule, QimaTranslationModule } from '@qima/ngx-qima';
import { AngularSvgIconModule } from 'angular-svg-icon';
import * as dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { NgxWebstorageModule } from 'ngx-webstorage';

import 'dayjs/locale/bn';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/hi';
import 'dayjs/locale/ta';
import 'dayjs/locale/vi';
import 'dayjs/locale/zh-cn';

dayjs.extend(localizedFormat);
dayjs.extend(timezone);

@NgModule({
  imports: [
    BrowserModule,
    NgxWebstorageModule.forRoot({
      prefix: 'jhi',
      separator: '-',
    }),
    AdminSharedModule.forRoot(),
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AdminSharedLibsModule,
    AdminRoutingModule,
    AdminEntitiesModule,
    AdminCoreModule,
    LayoutsModule,
    AccountModule,
    UserMgmtModule,
    ReportsManagementModule,
    QpLabelComponent,
    QpButtonDirective,
    QpTextComponent,
    QpCheckboxComponent,
    QpItemCountComponent,
    TranslateModule.forRoot({
      defaultLanguage: EQpLocale.ENGLISH,
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    QpPasswordStrengthBarComponent,
    // Used by ngx-qima library
    AngularSvgIconModule.forRoot(),
    QimaModule.forRoot(),
    QimaTranslationModule.forRoot(),
    QimaLoggerModule.forRoot({ loggerLevel: EQimaLoggerLevel.WARNING }),
    QpNotificationBarModule,
    LoginComponent,
    ErrorComponent,
    HomeComponent,
    PasswordResetFinishComponent,
    PasswordResetInitComponent,
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QpHttpParamsInterceptor,
      multi: true,
    },
    {
      provide: NZ_I18N,
      useValue: null,
    },
    {
      provide: QpPasswordService,
      useClass: PasswordService,
    } as ClassProvider,
    {
      provide: QpAddressService,
      useClass: AddressService,
    } as ClassProvider,
    {
      provide: QpCountryService,
      useClass: CountryService,
    } as ClassProvider,
    {
      provide: QpBrandTypeService,
      useClass: BrandTypeService,
    } as ClassProvider,
    {
      provide: QIMA_ASSETS_PATH_TOKEN,
      useValue: 'ngx-qima/assets/',
    } as ValueProvider,
    QP_NGX_QIMA_TRANSLATION_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AdminAppModule {
  public constructor(private readonly _translateService: TranslateService) {
    this._translateService.use('en');
  }
}
