<div class="main-container">
  <div
    *ngIf="isAuthenticated()"
    [ngClass]="currentMenuPosition === menuPositions.COLLAPSE ? 'navbar-container-collapsed' : 'navbar-container-expand'"
  >
    <qp-qima-desktop-main-navbar></qp-qima-desktop-main-navbar>
  </div>
  <div class="main-content">
    <qp-qima-subnav *ngIf="isAuthenticated() && routerUrl !== '/'"></qp-qima-subnav>
    <div [class.qp-container-fluid]="isAuthenticated()">
      <router-outlet></router-outlet>
    </div>
  </div>
  <router-outlet name="popup"></router-outlet>
</div>
