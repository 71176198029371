// @ts-strict-ignore
import { qpIsVndError } from '@library/functions/errors/qp-is-vnd-error';
import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { IQpVndError } from '@library/models/qp-vnd-error.models';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class QpAlertErrorService {
  public constructor(
    private readonly _qpNotificationBarService: QpNotificationBarService,
    private readonly _translateService: TranslateService
  ) {}

  public handleError(httpErrorResponse: HttpErrorResponse): void {
    const headerKeys = httpErrorResponse.headers?.keys();
    let errorHeader: string;
    let entityKey: string;

    if (qpIsVndError(httpErrorResponse)) {
      this._onVndErrors(httpErrorResponse.error);
    } else {
      switch (httpErrorResponse.status) {
        // connection refused, server not reachable
        case 0:
          this._qpNotificationBarService.error('error.server.not.reachable');
          break;

        case EQpHttpStatusCode.BAD_REQUEST:
          headerKeys
            .map((entry: string): string => entry.toLowerCase())
            .forEach((entry: string): void => {
              if (entry.endsWith('app-error')) {
                errorHeader = httpErrorResponse.headers.get(entry);
              } else if (entry.endsWith('app-params')) {
                entityKey = httpErrorResponse.headers.get(entry);
              }
            });

          if (errorHeader) {
            this._qpNotificationBarService.error(`global.menu.entities.${entityKey}`);
          } else if (httpErrorResponse.error !== '' && httpErrorResponse.error.fieldErrors) {
            httpErrorResponse.error.fieldErrors
              .map((fieldError: { code: string }): string =>
                ['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(fieldError.code) ? 'Size' : fieldError.code
              )
              .forEach((code: string): void => this._qpNotificationBarService.error(`error.${code}`));
          } else {
            this._qpNotificationBarService.error(httpErrorResponse.error);
          }

          break;

        case EQpHttpStatusCode.INTERNAL_SERVER_ERROR:
          this._qpNotificationBarService.error('error.server.not.reachable');
          break;

        default:
          if (!httpErrorResponse.error) {
            return;
          }

          if (httpErrorResponse.error.message) {
            this._qpNotificationBarService.error(httpErrorResponse.error.message);
          } else {
            this._qpNotificationBarService.error(httpErrorResponse.error);
          }
      }
    }
  }

  private _onVndErrors(errors: IQpVndError[]): void {
    errors.forEach((vndError: IQpVndError): void => {
      const translateKey = `error.VNDERRORS.${vndError.logref}`;
      const translatedError = this._translateService.instant(translateKey);

      if (translatedError === translateKey) {
        // on missing translation we print the english message
        this._qpNotificationBarService.error(vndError.message);
      } else {
        this._qpNotificationBarService.error(translatedError);
      }
    });
  }
}
