// @ts-strict-ignore
import { EQpAuthority } from '@library/models/rights/qp-authority.models';

export interface IDisplayedRoute {
  title: string;
  routerLink: string;
  routerLinkActiveOption: { exact: boolean };
  authorities: EQpAuthority[];
  children?: IDisplayedRoute[];
  iconName?: string;
  isDefaultPage?: boolean;
  defaultPageRoute?: IDisplayedRoute;
  isDefaultAction?: boolean;
  defaultActionRoute?: IDisplayedRoute;
  isSecondaryMenu?: boolean;
  dataCy?: string;
}

export class DisplayedRoute implements IDisplayedRoute {
  public static create(displayedRoute: {
    title: string;
    routerLink: string;
    routerLinkActiveOption: { exact: boolean };
    authorities: EQpAuthority[];
    children?: IDisplayedRoute[];
    iconName?: string;
    isDefaultPage?: boolean;
    defaultPageRoute?: IDisplayedRoute;
    isDefaultAction?: boolean;
    defaultActionRoute?: IDisplayedRoute;
    isSecondaryMenu?: boolean;
    dataCy?: string;
  }): DisplayedRoute {
    return new DisplayedRoute(
      displayedRoute.title,
      displayedRoute.routerLink,
      displayedRoute.routerLinkActiveOption,
      displayedRoute.authorities,
      displayedRoute.children,
      displayedRoute.iconName,
      displayedRoute.isDefaultPage,
      displayedRoute.defaultPageRoute,
      displayedRoute.isDefaultAction,
      displayedRoute.defaultActionRoute,
      displayedRoute.isSecondaryMenu,
      displayedRoute.dataCy
    );
  }

  private constructor(
    public title: string,
    public routerLink: string,
    public routerLinkActiveOption: { exact: boolean },
    public authorities: EQpAuthority[],
    public children: IDisplayedRoute[],
    public iconName: string,
    public isDefaultPage: boolean,
    public defaultPageRoute: IDisplayedRoute,
    public isDefaultAction: boolean,
    public defaultActionRoute: IDisplayedRoute,
    public isSecondaryMenu: boolean,
    public dataCy: string
  ) {}
}
