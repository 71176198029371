// @ts-strict-ignore
import { PasswordService } from '@admin/app/account/password/password.service';
import { AccountService } from '@admin/app/core/auth/account.service';
import { Account } from '@admin/app/core/user/account.model';
import { QpLabelComponent } from '@library/components/qp-label/qp-label.component';
import { QpPasswordFieldComponent } from '@library/components/qp-password-field/qp-password-field.component';
import { QpTextComponent } from '@library/components/qp-text/qp-text.component';
import { QpButtonDirective, EQpButtonDirectiveSize, EQpButtonDirectiveType } from '@library/directives/qp-button/qp-button.directive';
import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'admin-password',
  templateUrl: './password.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, QpLabelComponent, QpTextComponent, QpPasswordFieldComponent, QpButtonDirective, TranslateModule],
})
export class PasswordComponent implements OnInit, OnDestroy {
  public error: string;
  public success: string;
  public account: Account;
  public passwordFormArray = new UntypedFormArray([]);
  public passwordFormGroup = new UntypedFormGroup({
    newPassword: this.passwordFormArray,
    currentPassword: new UntypedFormControl('', Validators.required),
  });

  public buttonSizes: typeof EQpButtonDirectiveSize = EQpButtonDirectiveSize;
  public buttonTypes: typeof EQpButtonDirectiveType = EQpButtonDirectiveType;
  @ViewChild(QpPasswordFieldComponent) public passwordFieldComponent: QpPasswordFieldComponent;
  private _passwordSubscription: Subscription;

  public get currentPassword(): string {
    return this.passwordFormGroup.get('currentPassword').value;
  }

  public get newPassword(): string {
    return this.passwordFieldComponent.password;
  }

  public set newPassword(password: string) {
    this.passwordFieldComponent.password = password;
  }

  public set confirmPassword(password: string) {
    this.passwordFieldComponent.confirmPassword = password;
  }

  public get currentPasswordField(): UntypedFormControl {
    return this.passwordFormGroup.get('currentPassword') as UntypedFormControl;
  }

  public constructor(
    private readonly _passwordService: PasswordService,
    private readonly _accountService: AccountService
  ) {}

  public ngOnInit(): void {
    this._accountService.identity().then((account): void => {
      this.account = account;
    });
  }

  public ngOnDestroy(): void {
    if (this._passwordSubscription !== undefined) {
      this._passwordSubscription.unsubscribe();
    }
  }

  public linkFormGroup(formGroup: UntypedFormGroup): void {
    this.passwordFormArray.push(formGroup);
  }

  public changePassword(): void {
    this._passwordSubscription = this._passwordService.save$(this.newPassword, this.currentPassword).subscribe({
      next: (): void => {
        this.error = null;
        this.success = 'OK';
      },
      error: (): void => {
        this.success = null;
        this.error = 'ERROR';
      },
    });
  }
}
