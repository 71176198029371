<div [ngClass]="['direction--' + direction]">
  <form [formGroup]="passwordFormGroup" name="form" role="form">
    <div class="input-password" [class.input-password--full-width]="isFullWidth" [class.input-password--side-by-side]="!isFullWidth">
      <qp-label for="newPassword">{{ passwordLabelPlaceholder.label | translate }} </qp-label>
      <qp-input-password
        data-cy="new-password"
        formControlName="password"
        name="newPassword"
        [autocomplete]="inputPasswordAutocompletes.NEW_PASSWORD"
        [placeholder]="passwordLabelPlaceholder.placeholder | translate"
      >
      </qp-input-password>
      <qp-password-strength-bar [passwordSecurityScore]="passwordField['securityScore']"></qp-password-strength-bar>
      <div *ngIf="passwordField.dirty && passwordField.invalid">
        <qp-label *ngIf="!passwordField.value" error="true" type="informative">
          {{ 'global.messages.validate.newpassword.required' | translate }}
        </qp-label>
        <qp-label *ngIf="passwordField.errors.minlength" error="true" type="informative">
          {{ 'global.messages.validate.newpassword.minlength' | translate: { minLength: passwordMinLength } }}
        </qp-label>
        <qp-label *ngIf="passwordField.errors.maxlength" error="true" type="informative">
          {{ 'global.messages.validate.newpassword.maxlength' | translate: { maxLength: passwordMaxLength } }}
        </qp-label>
      </div>
    </div>
    <div
      class="input-password confirm"
      [class.input-password--full-width]="isFullWidth"
      [class.input-password--side-by-side]="!isFullWidth"
    >
      <qp-label>{{ confirmPasswordLabelPlaceholder.label | translate }} </qp-label>
      <qp-input-password
        data-cy="confirm-password"
        formControlName="confirmPassword"
        [autocomplete]="inputPasswordAutocompletes.NEW_PASSWORD"
        [placeholder]="confirmPasswordLabelPlaceholder.placeholder | translate"
      >
      </qp-input-password>
      <div *ngIf="confirmPasswordField.dirty && confirmPasswordField.invalid">
        <qp-label *ngIf="confirmPasswordField.errors.required" error="true" type="informative">
          {{ 'global.messages.validate.confirmpassword.required' | translate }}
        </qp-label>
        <qp-label *ngIf="confirmPasswordField.errors.minlength" error="true" type="informative">
          {{ 'global.messages.validate.confirmpassword.minlength' | translate: { minLength: passwordMinLength } }}
        </qp-label>
        <qp-label *ngIf="confirmPasswordField.errors.maxlength" class="qp-form-text qp-text-danger">
          {{ 'global.messages.validate.confirmpassword.maxlength' | translate: { maxLength: passwordMaxLength } }}
        </qp-label>
      </div>
      <qp-label
        *ngIf="
          passwordFormGroup.invalid &&
          passwordFormGroup.errors &&
          passwordFormGroup.errors.fieldsAreEqual &&
          confirmPasswordField.dirty &&
          passwordField.dirty
        "
        data-cy="error-password"
        error="true"
        type="informative"
      >
        {{ 'global.messages.error.dontmatch' | translate }}
      </qp-label>
    </div>
  </form>
</div>
