// @ts-strict-ignore
import { PasswordResetFinishService } from '@admin/app/account/password-reset/finish/password-reset-finish.service';
import { LoginModalService } from '@admin/app/shared/login/login-modal.service';
import { QpPasswordStrengthBarComponent } from '@library/components/qp-password-strength-bar/qp-password-strength-bar.component';
import { NgIf } from '@angular/common';
import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'admin-password-reset-finish',
  templateUrl: './password-reset-finish.component.html',
  styleUrls: ['./password-reset-finish.component.scss'],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FormsModule, QpPasswordStrengthBarComponent, TranslateModule],
})
export class PasswordResetFinishComponent implements OnInit, AfterViewInit {
  public confirmPassword: string;
  public doNotMatch: string;
  public error: string;
  public keyMissing: boolean;
  public resetAccount: QimaOptionalType<{
    password?: string;
  }>;

  public success: string;
  private key: string;

  public constructor(
    private readonly _passwordResetFinishService: PasswordResetFinishService,
    private readonly _loginModalService: LoginModalService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _elementRef: ElementRef
  ) {}

  public ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe((params: Params): void => {
      this.key = params['key'];
    });
    this.resetAccount = {};
    this.keyMissing = !this.key;
  }

  public ngAfterViewInit(): void {
    if (this._elementRef.nativeElement.querySelector('#password') != null) {
      this._elementRef.nativeElement.querySelector('#password').focus();
    }
  }

  public finishReset(): void {
    this.doNotMatch = null;
    this.error = null;

    if (this.resetAccount.password !== this.confirmPassword) {
      this.doNotMatch = 'ERROR';
    } else {
      this._passwordResetFinishService.save({ key: this.key, newPassword: this.resetAccount.password }).subscribe(
        (): void => {
          this.success = 'OK';
        },
        (): void => {
          this.success = null;
          this.error = 'ERROR';
        }
      );
    }
  }

  public login(): void {
    this._loginModalService.open();
  }
}
