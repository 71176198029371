<div [ngClass]="isCollapsed ? 'collapse' : 'expand'" class="menu-container" data-cy="desktop-navbar">
  <div class="top-menu">
    <div (click)="toggleCollapsed()" class="navigation-button-collapse menu-padding-left" data-cy="burger-btn">
      <div *ngIf="!isCollapsed" class="row-menu">
        <em nz-icon nzType="ic-collapse-menu"></em>
        <!-- @todo add a better and translated alt -->
        <img [src]="'assets/pictures/qima-one-logo.png'" alt="This is the Qima one logo in the side nav." class="menu-title" />
      </div>
      <em *ngIf="isCollapsed" nz-icon nzType="ic-menu"></em>
    </div>
    <ul [ngClass]="isCollapsed ? 'collapse' : 'expand'" [nzInlineCollapsed]="isCollapsed" class="navigation-menu" nz-menu nzMode="inline">
      <li *ngFor="let menu of menuList; let last = last" [class.divider]="last" class="menu" nz-menu-item>
        <span class="menu-item-tooltip">{{ menu.title | translate }}</span>
        <a
          [attr.data-cy]="menu.dataCy"
          [routerLinkActiveOptions]="menu.routerLinkActiveOption"
          [routerLinkActive]="menu.routerLink !== null ? 'menu-selected' : ''"
          [routerLink]="menu.defaultPageRoute?.routerLink || menu.routerLink"
          class="menu-item"
        >
          <!--Do not remove:
              these empty links are necessary to keep the routerLinkActive for the children routes
          -->
          <a *ngFor="let subEntity of menu.children" [routerLink]="subEntity?.routerLink"></a>
          <span class="row-menu menu-padding-left" title>
            <em [nzType]="menu.iconName" nz-icon></em>
            <span class="item">{{ menu.title | translate }}</span>
          </span>
        </a>
      </li>
    </ul>
  </div>

  <div class="bottom-menu">
    <div [ngClass]="isCollapsed ? 'collapse' : 'expand'" class="brand-name" data-cy="brand-name">{{ brandName }}</div>
    <div [ngClass]="isCollapsed ? 'shown' : 'hidden'" class="brand-ellipsis">...</div>
    <div class="logout-container">
      <div class="profile">
        <div class="picture">
          <img *ngIf="picture" [src]="picture" alt="profile picture" />
          <em *ngIf="!picture" nz-icon nzType="ic-username"></em>
        </div>
        <div [ngClass]="isCollapsed ? 'collapse' : 'expand'" class="information">
          <div class="name">{{ account?.firstName }}</div>
          <div class="role">{{ (account?.profiles)[0] | authorityLabel | translate }}</div>
        </div>
      </div>
      <div [ngClass]="isCollapsed ? 'collapse' : 'expand'" class="logout-btn">
        <div class="separate"></div>
        <a (click)="logout()" data-cy="logout-btn">
          <em nz-icon nzType="ic-logout"></em>
        </a>
      </div>
    </div>
  </div>
</div>
